import React, { useState, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, CardTitle, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import "flatpickr/dist/themes/material_green.css";

import Breadcrumbs from '../../components/Common/Breadcrumb';
import "flatpickr/dist/themes/material_green.css";
import _ from 'lodash';
import Details from './details';
import { fetchInvoice } from '../../store/actions';

const Invoice = () => {
  const [modal, setModal] = useState(false);
  const [current, setCurrent] = useState(null);
  const [q, setQ] = useState('');
  const dispatch = useDispatch();
  const invoices = useSelector(state => state.invoice?.data || [])
  const loading = useSelector(state => state.table?.loading || false)
  const [fInvoices, setFInvoices] = useState([]);
//   const [page, setPage] = useState(1);
//   const [perPage, setPerPage] = useState(10);

  const toggleModal = data => {
    if (data) dispatch(fetchInvoice(data.id))
    setCurrent(data || null)
    return setModal(!modal);
  }

  useEffect(() => {
    dispatch(fetchInvoice())
  }, [])

  useEffect(() => {
    if (invoices) {
      if (q) {
        const filteredInvoices = fInvoices.filter(el => {
          const fullName = `${el.ref}`.toString().toLowerCase();
          return fullName.includes(q.toLowerCase())
        })
        setFInvoices(filteredInvoices) 
      }
      else setFInvoices(invoices)
    }
    else setFInvoices(invoices)
  }, [invoices, q])

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Reference</span>,
            selector: row => row.ref,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Date</span>,
            selector: row => row.date,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Issue date</span>,
            selector: row => row.issue_date,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Total</span>,
            selector: row => row.total,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Status</span>,
            sortable: true,
            selector: (cell) => <span className="badge p-2 px-2" style={{ backgroundColor: cell.t_status_items.color }}><i className={`mdi me-1 ${cell.t_status_items.iconName}`}></i> {cell.t_status_items?.name} </span>,
        },
        {
            name: <span className='font-weight-bold fs-13'>Actions</span>,
            sortable: true,
            cell: (cell) => {
                return (
                    <>
                        <button onClick={() => toggleModal(cell)} className="btn btn-sm btn-primary me-2 shadow text-nowrap px-3"><i className="ri-edit-line align-bottom"></i></button>
                        {/* <button onClick={() => toggleModal1(cell)} className="btn btn-sm btn-danger me-2 shadow text-nowrap px-3"><i className="ri-delete-bin-line align-bottom"></i></button> */}
                    </>
                );
            },
        },       
    ];

    return (
        <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="My restaurant" breadcrumbItem="Invoices" />
            { /* Tables */ }
            <Card>
              <CardBody>
              <Row>
                <Col md={6} />
                <Col md={6}>
                  <div className="d-flex align-items-center justify-content-end">
                      <form className="app-search d-none d-lg-block p-0 me-2" style={{ width: '100%' }}>
                          <div className="position-relative">
                          <Input
                            value={q}
                            onChange={e => setQ(e.target.value)}
                            type="text"
                            debounce={600}
                            placeholder="Search by name, reference..."
                            name="q" />
                          <span className="ri-search-line"></span>
                          </div>
                      </form>
                      <button onClick={() => toggleModal()} type="button" className="btn btn-primary text-nowrap">
                        <i className="ri-add-line align-bottom me-1"></i>
                        Add invoice
                      </button>
                  </div>
                </Col>
            </Row>
            <DataTable
                columns={columns}
                data={fInvoices}
                pagination
                // paginationServer
                // paginationDefaultPage={page}
                // paginationPerPage={perPage}
                // onChangePage={setPage}
                // onChangeRowsPerPage={setPerPage}
                progressPending={loading}
            />

            <Modal
                isOpen={modal}
                toggle={() => { toggleModal() }}
                centered
                scrollable={true}
                size="lg"
                >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">
                    {current ? 'Update' : 'Add'} Invoice
                    </h5>
                    <button
                    type="button"
                    onClick={() => { setModal(false) }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ maxHeight: 600 }}>
                    <Details info={current} />
                </div>
            </Modal>
              </CardBody>
            </Card>
  
          </Container>
        </div>
      </React.Fragment>
    );
};

export default Invoice;