import { ADD_PROMOTION, DELETE_PROMOTION, EDIT_PROMOTION, FETCH_PROMOTION, FETCH_PROMOTIONS, PROMOTION_ERROR, PROMOTION_SUCCESS, SET_PROMOTION, SET_PROMOTIONS } from "./actionTypes"

export const fetchPromotions = data => {
  return {
    type: FETCH_PROMOTIONS,
    payload: data
  }
}

export const setPromotions = data => {
  return {
    type: SET_PROMOTIONS,
    payload: data,
  }
}

export const fetchPromotion = data => {
  return {
    type: FETCH_PROMOTION,
    payload: data
  }
}

export const setPromotion = data => {
  return {
    type: SET_PROMOTION,
    payload: data,
  }
}

export const addPromotion = data => {
  return {
    type: ADD_PROMOTION,
    payload: data,
  }
}

export const editPromotion = data => {
  return {
    type: EDIT_PROMOTION,
    payload: data,
  }
}

export const removePromotion = id => {
  return {
    type: DELETE_PROMOTION,
    payload: id,
  }
}

export const promotionSuccess = msg => {
  return {
    type: PROMOTION_SUCCESS,
    payload: msg,
  }
}

export const promotionError = error => {
  return {
    type: PROMOTION_ERROR,
    payload: error,
  }
}
