import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ADD_INVOICE, DELETE_INVOICE, EDIT_INVOICE, FETCH_INVOICE, FETCH_INVOICES } from "./actionTypes"
import { setInvoices, setInvoice, invoiceSuccess, invoiceError } from "./actions"

//Include Both Helper File with needed methods
import { deleteInvoice, getInvoice, getInvoices, postInvoice, putInvoice } from "../../helpers/backend_helper"


function* fetchInvoices({ payload: data }) {
  try {
    const response = yield call(getInvoices, data)
    yield put(setInvoices(response))
    yield put(invoiceSuccess(response))
  } catch (error) {
    yield put(invoiceError(error))
  }
}

function* fetchInvoice({ payload: data }) {
  try {
    const response = yield call(getInvoice, data)
    yield put(setInvoice(response))
    yield put(invoiceSuccess(response))
  } catch (error) {
    yield put(invoiceError(error))
  }
}

function* addInvoice({ payload: data }) {
  try {
     yield call(postInvoice, data)
    const response = yield call(getInvoices, data)
    yield put(setInvoices(response))
    yield put(invoiceSuccess(response))
  } catch (error) {
    yield put(invoiceError(error))
  }
}

function* editInvoice({ payload: data }) {
  try {
     yield call(putInvoice, data)
    const response = yield call(getInvoices, data)
    yield put(setInvoices(response))
    yield put(invoiceSuccess(response))
  } catch (error) {
    yield put(invoiceError(error))
  }
}

function* removeInvoice({ payload: data }) {
  try {
     yield call(deleteInvoice, data)
    const response = yield call(getInvoices, data)
    yield put(setInvoices(response))
    yield put(invoiceSuccess(response))
  } catch (error) {
    yield put(invoiceError(error))
  }
}

export function* watchInvoice() {
  yield takeEvery(FETCH_INVOICES, fetchInvoices)
  yield takeEvery(FETCH_INVOICE, fetchInvoice)
  yield takeEvery(ADD_INVOICE, addInvoice)
  yield takeEvery(EDIT_INVOICE, editInvoice)
  yield takeEvery(DELETE_INVOICE, removeInvoice)
}

function* InvoiceSaga() {
  yield all([fork(watchInvoice)])
}

export default InvoiceSaga;
