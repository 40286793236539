import { APIClient } from "./api_helper";
import * as url from "./url_helper";

const api = new APIClient();
// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};
const user = getLoggedInUser();

// Login Method
const postLogin = data => api.create(url.POST_LOGIN, data);

// postForgetPwd
const postForgetPwd = data => api.create(url.POST_PASSWORD_FORGET, data);

// Profile
const getProfile = () => api.get(url.GET_FETCH_PROFILE);
const putProfile = data => api.create(`${url.POST_PROFILE}/${user.id}`, data);
const updateEmail = data => api.update(`${url.PUT_PROFILE_EMAIL}/${user.id}`, data);
const updatePassword = data => api.update(`${url.PUT_PROFILE_PASSWORD}/${user.id}`, data);

// Restaurant
const getResto = () => api.get(url.GET_FETCH_RESTO);
const putRestoInfo = data => api.update(`${url.PUT_RESTO_INFO}/${user.resto}`, data);
const putRestoContact = data => api.update(`${url.PUT_RESTO_CONTACT}/${user.resto}`, data);
const putRestoAddress = data => api.update(`${url.PUT_RESTO_ADDRESS}/${user.resto}`, data);
const getKitchen = () => api.get(url.GET_FETCH_KITCHEN);

// Users
const putRestoUser = data => api.update(`${url.PUT_RESTO_USER}/${data.id}`, {
  firstname: data.firstname,
  lastname: data.lastname,
  role: data.role,
  email: data.email,
  phone: data.phone,
  password: data.password,
});
const postRestoUser = data => {api.create(`${url.POST_RESTO_USER}/${data.id}`, {
  firstname: data.firstname,
  lastname: data.lastname,
  role: data.role,
  email: data.email,
  phone: data.phone,
  password: data.password,
})};
const deleteRestoUser = id => api.delete(`${url.DELETE_RESTO_USER}/${id}`);
const getRoles = () => api.get(url.GET_FETCH_ROLES);

// Shifts
const putShift = data => api.update(`${url.PUT_SHIFT}/${data.id}`, {
  hourB: data.hourB,
  hourE: data.hourE,
  name: data.name,
});

const postShift = data => api.create(`${url.POST_SHIFT}/${data.id}`, {
  hourB: data.hourB,
  hourE: data.hourE,
  name: data.name,
});
const deleteShift = id => api.delete(`${url.DELETE_SHIFT}/${id}`);

// Kitchens
const postKitchen = data => api.create(url.POST_KITCHEN, data);
const deleteKitchen = id => api.delete(`${url.DELETE_KITCHEN}/${id}`);

// Reservations
const getReservations = data => api.get(url.GET_FETCH_RESERVATIONS, { params: data });

// Tables & Rooms
const getTables = data => api.get(url.GET_FETCH_TABLES, { params: { if: user.resto, ...data } })
const postRoom = data => api.create(url.POST_ROOM, data);
const putRoom = data => api.update(`${url.PUT_ROOM}/${data.id}`, data);
const deleteRoom = id => api.delete(`${url.DELETE_ROOM}/${id}`);
const getRooms = () => api.get(`${url.GET_FETCH_ROOMS}/${user.resto}`);
const getRoom = id => api.get(`${url.GET_FETCH_ROOM}/${id}`);

// Menus
const getMenus = data => api.get(url.GET_FETCH_MENUS)
const getMenu = id => api.get(`${url.GET_FETCH_MENU}/${id}`);
const postMenu = data => api.create(url.POST_MENU, data);
const putMenu = data => api.update(`${url.PUT_MENU}/${data.id}`, data);
const deleteMenu = id => api.delete(`${url.DELETE_MENU}/${id}`);

// Invoice
const getInvoices = data => api.get(url.GET_FETCH_INVOICES)
const getInvoice = id => api.get(`${url.GET_FETCH_INVOICE}/${id}`);
const postInvoice = data => api.create(url.POST_INVOICE, data);
const putInvoice = data => api.update(`${url.PUT_INVOICE}/${data.id}`, data);
const deleteInvoice = id => api.delete(`${url.DELETE_INVOICE}/${id}`);

// Promotion
const getPromotions = () => api.get(url.GET_FETCH_PROMOTIONS)
const getPromotion = id => api.get(`${url.GET_FETCH_PROMOTION}/${id}`);
const postPromotion = data => api.create(url.POST_PROMOTION, data);
const putPromotion = data => api.update(`${url.PUT_PROMOTION}/${data.id}`, data);
const deletePromotion = id => api.delete(`${url.DELETE_PROMOTION}/${id}`);

// Image
const getImages = () => api.get(`${url.GET_FETCH_IMAGES}`)
const uploadImage = data => api.create(`${url.POST_UPLOAD_IMAGE}`, data);
const postImage = data => api.upload(url.POST_IMAGE, data);
const putImage = data => api.update(url.PUT_IMAGE, data);
const deleteImage = id => api.delete(`${url.DELETE_IMAGE}/${id}`);

// Contract
const getContracts = () => api.get(url.GET_FETCH_CONTRACTS)
const getContract = id => api.get(`${url.GET_FETCH_CONTRACT}/${id}`);
const postContract = data => api.create(url.POST_CONTRACT, data);
const putContract = data => api.update(`${url.PUT_CONTRACT}/${data.id}`, data);
const deleteContract = id => api.delete(`${url.DELETE_CONTRACT}/${id}`);


export {
  getLoggedInUser,
  isUserAuthenticated,
  postLogin,
  postForgetPwd,
  getProfile,
  putProfile,
  updateEmail,
  updatePassword,

  // Restaurant
  getResto,
  putRestoInfo,
  putRestoAddress,
  putRestoContact,
  getKitchen,

  // Users
  putRestoUser,
  postRestoUser,
  deleteRestoUser,
  getRoles,

  // Shifts
  putShift,
  postShift,
  deleteShift,

  // Kitchens
  postKitchen,
  deleteKitchen,

  // Reservations
  getReservations,

  // Tables
  getTables,
  postRoom,
  putRoom,
  deleteRoom,
  getRooms,
  getRoom,

  // Menus
  getMenus,
  getMenu,
  postMenu,
  putMenu,
  deleteMenu,

  // Invoices
  getInvoices,
  getInvoice,
  postInvoice,
  putInvoice,
  deleteInvoice,

  // Promotions
  getPromotions,
  getPromotion,
  postPromotion,
  putPromotion,
  deletePromotion,

  // Images
  getImages,
  uploadImage,
  postImage,
  putImage,
  deleteImage,

  // Contracts
  getContracts,
  getContract,
  postContract,
  putContract,
  deleteContract,

};
