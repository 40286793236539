import { ADD_CONTRACT, DELETE_CONTRACT, EDIT_CONTRACT, FETCH_CONTRACT, FETCH_CONTRACTS, CONTRACT_ERROR, CONTRACT_SUCCESS, SET_CONTRACT, SET_CONTRACTS } from "./actionTypes"

export const fetchContracts = data => {
  return {
    type: FETCH_CONTRACTS,
    payload: data
  }
}

export const setContracts = data => {
  return {
    type: SET_CONTRACTS,
    payload: data,
  }
}

export const fetchContract = data => {
  return {
    type: FETCH_CONTRACT,
    payload: data
  }
}

export const setContract = data => {
  return {
    type: SET_CONTRACT,
    payload: data,
  }
}

export const addContract = data => {
  return {
    type: ADD_CONTRACT,
    payload: data,
  }
}

export const editContract = data => {
  return {
    type: EDIT_CONTRACT,
    payload: data,
  }
}

export const removeContract = id => {
  return {
    type: DELETE_CONTRACT,
    payload: id,
  }
}

export const contractSuccess = msg => {
  return {
    type: CONTRACT_SUCCESS,
    payload: msg,
  }
}

export const contractError = error => {
  return {
    type: CONTRACT_ERROR,
    payload: error,
  }
}
