import { ADD_IMAGE, DELETE_IMAGE, EDIT_IMAGE, FETCH_IMAGE, FETCH_IMAGES, IMAGE_ERROR, IMAGE_SUCCESS, SET_IMAGE, SET_IMAGES } from "./actionTypes"

export const fetchImages = () => {
  return {
    type: FETCH_IMAGES,
  }
}

export const setImages = data => {
  return {
    type: SET_IMAGES,
    payload: data,
  }
}

// export const fetchImage = data => {
//   return {
//     type: FETCH_IMAGE,
//     payload: data
//   }
// }

// export const setImage = data => {
//   return {
//     type: SET_IMAGE,
//     payload: data,
//   }
// }

export const addImage = data => {
  return {
    type: ADD_IMAGE,
    payload: data,
  }
}

export const editImage = data => {
  return {
    type: EDIT_IMAGE,
    payload: data,
  }
}

export const removeImage = id => {
  return {
    type: DELETE_IMAGE,
    payload: id,
  }
}

export const imageSuccess = msg => {
  return {
    type: IMAGE_SUCCESS,
    payload: msg,
  }
}

export const imageError = error => {
  return {
    type: IMAGE_ERROR,
    payload: error,
  }
}
