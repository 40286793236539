export const FETCH_MENUS = "FETCH_MENUS";
export const SET_MENUS = "SET_MENUS";
export const FETCH_MENU = "FETCH_MENU";
export const SET_MENU = "SET_MENU";
export const ADD_MENU = "ADD_MENU";
export const EDIT_MENU = "EDIT_MENU";
export const DELETE_MENU = "DELETE_MENU";
export const MENU_SUCCESS = "MENU_SUCCESS";
export const MENU_ERROR = "MENU_ERROR";


