import { AccordionBody, AccordionHeader, AccordionItem, Col, FormGroup, Input, Row } from "reactstrap";
import Details from "./details";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import ElItem from "./ElItem";
import { HTML5Backend } from "react-dnd-html5-backend";
const ItemType = "BOX";

const CatItem = ({ cat, index, moveCat, setCategoryName, addElRow, deleteCategory, moveEl, setElementName, deleteElement }) => {
    // Drag functionality
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Drop functionality
  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveCat(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

//   const moveEl = (dragIndex, hoverIndex) => {
//     const elements = categories
//     const draggedEl = elements[dragIndex];
//     const updatedEl = [...elements];
//     updatedEl.splice(dragIndex, 1);
//     updatedEl.splice(hoverIndex, 0, draggedEl);
//     setEls(updatedEl)
// };
  
  return (
    <AccordionItem>
        <AccordionHeader targetId={index + 1}>
            <span ref={(node) => drag(drop(node))} style={{ cursor: 'move', paddingRight: '10px', paddingLeft: '10px', paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold' }}>
                <b>☰</b>
            </span>
            <b className="flex-grow-1">{cat.name || 'New category'}</b>
        </AccordionHeader>
        <AccordionBody accordionId={index + 1}>
        <div className="border-bottom mt-2">
            <Row>
                <Col cols={1}><i class="ri-apps-2-line d-flex justify-content-end"></i></Col>
                <Col md={11}>
                    <FormGroup>
                        {/* <Label for="category">Category</Label> */}
                        <div className="d-flex align-items-center">
                            <Input placeholder="Category name" value={cat.name} onChange={e => setCategoryName(e.target.value, index)} type="text" name="category" />
                            <button onClick={() => addElRow(index)} type="button" className="btn btn-sm btn-success text-nowrap ms-2">
                            <i class="ri-checkbox-blank-circle-line"></i> Add item
                            </button>
                            <button onClick={() => deleteCategory(index)} type="button" className="btn btn-sm btn-outline-secondary ms-2">
                                X
                            </button>
                        </div>
                    </FormGroup>
                </Col>
            </Row>
            <DndProvider backend={HTML5Backend}>
                {cat?.elements?.map((el, idx) => (
                    <ElItem
                        key={idx}
                        el={el}
                        setElementName={setElementName}
                        deleteElement={deleteElement}
                        moveEl={moveEl}
                        index={idx}
                        catIndex={index}
                    />
                ))}
            </DndProvider>
        </div>
        </AccordionBody>
    </AccordionItem>
  )
}

export default CatItem;