import React, { useState, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, CardTitle, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import "flatpickr/dist/themes/material_green.css";

import Breadcrumbs from '../../components/Common/Breadcrumb';
import "flatpickr/dist/themes/material_green.css";
import _ from 'lodash';
import Details from './details';
import { fetchPromotion, fetchPromotions, removePromotion } from '../../store/promotion/actions';

const Promotion = () => {
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [current, setCurrent] = useState(null);
  const [q, setQ] = useState('');
  const dispatch = useDispatch();
  const promotions = useSelector(state => state.promotion?.data || [])
  const loading = useSelector(state => state.promotion?.loading || false)
  const [fPromotions, setFPromotions] = useState([]);
//   const [page, setPage] = useState(1);
//   const [perPage, setPerPage] = useState(10);

  const toggleModal = data => {
    if (data) dispatch(fetchPromotion(data.id))
    setCurrent(data || null)
    return setModal(!modal);
  }

  const toggleModal1 = data => {
    setCurrent(data || null)
    return setModal1(!modal);
  }

  const deletePromotion = () => {
    dispatch(removePromotion(current.id))
    setModal1(false)
  }

  useEffect(() => {
    dispatch(fetchPromotions())
  }, [])

  useEffect(() => {
    if (promotions) {
      if (q) {
        const filteredPromotions = fPromotions.filter(el => {
          const fullName = `${el.ref}`.toString().toLowerCase();
          return fullName.includes(q.toLowerCase())
        })
        setFPromotions(filteredPromotions) 
      }
      else setFPromotions(promotions)
    }
    else setFPromotions(promotions)
  }, [promotions, q])

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Promotion</span>,
            selector: row => row.name,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Value</span>,
            selector: row => row.promo_value,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Type</span>,
            selector: row => row.promo_value_type,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Start date</span>,
            selector: row => row.date_from,
            sortable: true
        },
        {
          name: <span className='font-weight-bold fs-13'>End date</span>,
          selector: row => row.date_to,
          sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Actions</span>,
            sortable: true,
            cell: (cell) => {
                return (
                    <>
                      <button onClick={() => toggleModal(cell)} className="btn btn-sm btn-primary me-2 shadow text-nowrap px-3"><i className="ri-edit-line align-bottom"></i></button>
                      <button onClick={() => toggleModal1(cell)} className="btn btn-sm btn-danger me-2 shadow text-nowrap px-3"><i className="ri-delete-bin-line align-bottom"></i></button>
                    </>
                );
            },
        },       
    ];

    return (
        <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="My restaurant" breadcrumbItem="promotions" />
            { /* Tables */ }
            <Card>
              <CardBody>
                <Row>
                  <Col md={6} />
                  <Col md={6}>
                    <div className="d-flex align-items-center justify-content-end">
                        <form className="app-search d-none d-lg-block p-0 me-2" style={{ width: '100%' }}>
                            <div className="position-relative">
                            <Input
                              value={q}
                              onChange={e => setQ(e.target.value)}
                              type="text"
                              debounce={600}
                              placeholder="Search by name, reference..."
                              name="q" />
                            <span className="ri-search-line"></span>
                            </div>
                        </form>
                        <button onClick={() => toggleModal()} type="button" className="btn btn-primary text-nowrap">
                          <i className="ri-add-line align-bottom me-1"></i>
                          Add promotion
                        </button>
                    </div>
                  </Col>
                </Row>
                <DataTable
                  columns={columns}
                  data={fPromotions}
                  pagination
                  // paginationServer
                  // paginationDefaultPage={page}
                  // paginationPerPage={perPage}
                  // onChangePage={setPage}
                  // onChangeRowsPerPage={setPerPage}
                  progressPending={loading}
                />

                <Modal
                    isOpen={modal}
                    toggle={() => { toggleModal() }}
                    centered
                    scrollable={true}
                    size="lg"
                    >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                        {current ? 'Update' : 'Add'} Promotion
                        </h5>
                        <button
                        type="button"
                        onClick={() => { setModal(false) }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        >
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ maxHeight: 600 }}>
                        <Details info={current} onChange={() => setModal(false)} />
                    </div>
                </Modal>

                <Modal
                  isOpen={modal1}
                  toggle={() => { toggleModal1() }}
                  centered
                  >
                  <div className="modal-header">
                      <h5 className="modal-title mt-0">
                      Delete user
                      </h5>
                      <button
                      type="button"
                      onClick={() => { setModal1(false) }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      >
                      <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div className="modal-body d-flex justify-content-center" style={{ maxHeight: 400 }}>
                    <h5>Do you want to delete <strong>{current?.name}</strong>?</h5>
                  </div>
                  <div className="modal-footer d-flex justify-content-center">
                  <button onClick={() => deletePromotion()} type="submit" class="btn btn-danger px-4">Yes</button>
                  <button onClick={() => setModal1(false)} type="submit" class="btn btn-secondary px-4">No</button>

                  </div>
                </Modal>
              </CardBody>
            </Card>
  
          </Container>
        </div>
      </React.Fragment>
    );
};

export default Promotion;