import React, { useEffect } from "react";

import { Card, CardBody, CardTitle, Col, Container, Row} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { fetchKitchen, fetchResto } from "../../store/actions";
import RestoCard from "./card";
import Location from "./map";
import SimpleBar from "simplebar-react";
import Users from "./users";
import Shifts from "./shifts";
import Kitchen from "./kitchen";

const Restaurant = () => {
  const dispatch = useDispatch();
  const info = useSelector(state => state.restaurant.resto);
  const kitchens = useSelector(state => state.restaurant.kitchen);
  const user = JSON.parse(localStorage.authUser);
  
  useEffect(() => {
    dispatch(fetchResto())
    dispatch(fetchKitchen())
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="My restaurant" breadcrumbItem="Information" />
          <Row>
            <Col >
              <SimpleBar style={{ maxHeight: 500 }}>
                <RestoCard
                  type="info"
                  title="My restaurant"
                  info={{
                    id: info.id,
                    ref: info.ref,
                    name: info.name,
                    description: info.description,
                  }}
                />

                <RestoCard
                  type="owner"
                  title="Owner"
                  notEditable={true}
                  info={{
                    id: info.id,
                    firstname: user.firstname,
                    lastname: user.lasrname,
                    phone: user.phone,
                    email: user.email,
                  }}
                />

                <RestoCard
                  type="contact"
                  title="Contact"
                  info={{
                    id: info.id,
                    firstname: info.data?.owner_firstname,
                    lastname: info.data?.owner_lasrname,
                    phone: info.data?.owner_phone,
                    email: info.data?.owner_email,
                  }}
                />

                <RestoCard
                  type="address"
                  title="Address"
                  info={{
                    id: info.t_address?.id,
                    line1: info.t_address?.line1,
                    line2: info.t_address?.line2,
                    zipcode: info.t_address?.zipcode,
                    city: info.t_address?.city,
                  }}
                />
              </SimpleBar>
              </Col>
            <Col>
              <Location />
          </Col>
          </Row>
          { /* Tables */ }
          <Card>
            <CardBody>
            <CardTitle className="mt-0">Personal</CardTitle>
            <Users data={info.t_user} />
            </CardBody>
          </Card>

          <Card>
            <CardBody>
            <CardTitle className="mt-0">Shifts</CardTitle>
            <Shifts data={info.t_shift} />
            </CardBody>
          </Card>

          <Card>
            <CardBody>
            <CardTitle className="mt-0">Kitchen</CardTitle>
            <Kitchen data={info.r_resto_kitchen} kitchens={kitchens} />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Restaurant;
