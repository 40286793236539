import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ADD_MENU, DELETE_MENU, EDIT_MENU, FETCH_MENU, FETCH_MENUS } from "./actionTypes"
import { setMenus, setMenu, menuSuccess, menuError } from "./actions"

//Include Both Helper File with needed methods
import { deleteMenu, getMenu, getMenus, postMenu, putMenu } from "../../helpers/backend_helper"


function* fetchMenus({ payload: data }) {
  try {
    const response = yield call(getMenus, data)
    yield put(setMenus(response))
    yield put(menuSuccess(response))
  } catch (error) {
    yield put(menuError(error))
  }
}

function* fetchMenu({ payload: data }) {
  try {
    const response = yield call(getMenu, data)
    yield put(setMenu(response))
    yield put(menuSuccess(response))
  } catch (error) {
    yield put(menuError(error))
  }
}

function* addMenu({ payload: data }) {
  try {
     yield call(postMenu, data)
    const response = yield call(getMenus, data)
    yield put(setMenus(response))
    yield put(menuSuccess(response))
  } catch (error) {
    yield put(menuError(error))
  }
}

function* editMenu({ payload: data }) {
  try {
     yield call(putMenu, data)
    const response = yield call(getMenus, data)
    yield put(setMenus(response))
    yield put(menuSuccess(response))
  } catch (error) {
    yield put(menuError(error))
  }
}

function* removeMenu({ payload: data }) {
  try {
     yield call(deleteMenu, data)
    const response = yield call(getMenus, data)
    yield put(setMenus(response))
    yield put(menuSuccess(response))
  } catch (error) {
    yield put(menuError(error))
  }
}

export function* watchMenu() {
  yield takeEvery(FETCH_MENUS, fetchMenus)
  yield takeEvery(FETCH_MENU, fetchMenu)
  yield takeEvery(ADD_MENU, addMenu)
  yield takeEvery(EDIT_MENU, editMenu)
  yield takeEvery(DELETE_MENU, removeMenu)
}

function* MenuSaga() {
  yield all([fork(watchMenu)])
}

export default MenuSaga;
