import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ADD_CONTRACT, DELETE_CONTRACT, EDIT_CONTRACT, FETCH_CONTRACT, FETCH_CONTRACTS } from "./actionTypes"
import { setContracts, setContract, contractSuccess, contractError } from "./actions"

//Include Both Helper File with needed methods
import { deleteContract, getContract, getContracts, postContract, putContract } from "../../helpers/backend_helper"


function* fetchContracts({ payload: data }) {
  try {
    const response = yield call(getContracts, data)
    yield put(setContracts(response))
    yield put(contractSuccess(response))
  } catch (error) {
    yield put(contractError(error))
  }
}

function* fetchContract({ payload: data }) {
  try {
    const response = yield call(getContract, data)
    yield put(setContract(response))
    yield put(contractSuccess(response))
  } catch (error) {
    yield put(contractError(error))
  }
}

function* addContract({ payload: data }) {
  try {
     yield call(postContract, data)
    const response = yield call(getContracts, data)
    yield put(setContracts(response))
    yield put(contractSuccess(response))
  } catch (error) {
    yield put(contractError(error))
  }
}

function* editContract({ payload: data }) {
  try {
     yield call(putContract, data)
    const response = yield call(getContracts, data)
    yield put(setContracts(response))
    yield put(contractSuccess(response))
  } catch (error) {
    yield put(contractError(error))
  }
}

function* removeContract({ payload: data }) {
  try {
     yield call(deleteContract, data)
    const response = yield call(getContracts, data)
    yield put(setContracts(response))
    yield put(contractSuccess(response))
  } catch (error) {
    yield put(contractError(error))
  }
}

export function* watchContract() {
  yield takeEvery(FETCH_CONTRACTS, fetchContracts)
  yield takeEvery(FETCH_CONTRACT, fetchContract)
  yield takeEvery(ADD_CONTRACT, addContract)
  yield takeEvery(EDIT_CONTRACT, editContract)
  yield takeEvery(DELETE_CONTRACT, removeContract)
}

function* ContractSaga() {
  yield all([fork(watchContract)])
}

export default ContractSaga;
