import React from "react";

import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import UserPanel from "./UserPanel";
import Approval from "./Approval";

const Dashboard = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Mymeal" breadcrumbItem="Dashboard" />
          {/* User Panel Charts */}
          <UserPanel />
          {/* <OverView /> */}
          <Approval />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
