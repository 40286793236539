import React, { useRef, useState } from "react";
import { FormGroup, Input, Label, Tooltip } from "reactstrap";

const ExtButton = () => {
    const [btnText, setBtnText] = useState('Link to restaurant')
    const [tooltipTxt, setTooltepTxt] = useState('Your tooltip goes here')
    const [btnColor, setBtnColor] = useState('#008000');
    const [textColor, setTextColor] = useState('#ffffff');
    const [shadowColor, setShadowColor] = useState('#008000');
    const [isCosde, setIsCode] = useState(false);
    const [ttop, setttop] = useState(false);
    const [background, setBackground] = useState('#fff')

    const codeRef = useRef();

    const generateCode = () => {
        setIsCode(true)
        const codeText = codeRef.current?.textContent || `<a title="${tooltipTxt}" href="" class="button" style="background: ${btnColor}; color: ${textColor}; padding: 4px 10px; border-radius: 6px; text-decoration: none; font-family: sans-serif;">${btnText}</a>`;
        navigator.clipboard.writeText(codeText)
        .then(() => {
            alert("Code copied to clipboard!");
        })
        .catch(err => {
            console.error('Failed to copy: ', err);
            alert('Failed to copy')
        });
    }

  return (
    <React.Fragment>
      <div className="position-relative d-flex align-items-center justify-content-center border rounded" style={{ height: 160, width: '100%', backgroundColor: background }}>
            <div className="position-absolute top-0 end-0 d-flex align-items-center mt-2 me-2">
                <button
                    onClick={() => setBackground('#fff')}
                    className={`btn btn-light p-2 rounded-circle me-2 shadow`}
                ></button>
                <button
                    onClick={() => setBackground('#eee')}
                    className={`btn btn-secondary p-2 rounded-circle shadow`}
                ></button>
            </div>
            <Tooltip
                placement="top"
                isOpen={ttop}
                target="btnTooltip"
                toggle={() => {
                    setttop(!ttop);
                }}
            >
                {tooltipTxt}
            </Tooltip>
            <button
                id="btnTooltip"
                className={`btn btn-primary`}
                style={{
                    color: textColor,
                    backgroundColor: btnColor,
                    shadowColor: `${shadowColor}`
                }}
            >{btnText}</button>
      </div>
            <FormGroup>
                <Label for="button-text" size="sm">Button text</Label>
                <Input placeholder="Button text" value={btnText} onChange={e => setBtnText(e.target.value)} type="text" name="btnText" />
            </FormGroup>
            <FormGroup>
                <Label for="tooltip-text" size="sm">Tooltip text</Label>
                <Input placeholder="Tooltip text when hover over the button" value={tooltipTxt} onChange={e => setTooltepTxt(e.target.value)} type="text" name="tooltip" />
            </FormGroup>
            <div className="d-flex align-items-center flex-wrap">
                <FormGroup className="flex-grow-1 me-3">
                    <Label for="text-color" size="sm">Text color</Label>
                    <Input placeholder="Button text" value={textColor} onChange={e => setTextColor(e.target.value)} type="color" name="textColor" />
                </FormGroup>
                <FormGroup className="flex-grow-1 me-3">
                    <Label for="button-color" size="sm">Button color</Label>
                    <Input placeholder="Button color" value={btnColor} onChange={e => setBtnColor(e.target.value)} type="color" name="btnColor" />
                </FormGroup>
                <FormGroup className="flex-grow-1 me-3">
                    <Label for="shadow-color" size="sm">Shadow color</Label>
                    <Input placeholder="Shadow color" value={shadowColor} onChange={e => setShadowColor(e.target.value)} type="color" name="shadowColor" />
                </FormGroup>
            </div>
            {isCosde && <div ref={codeRef} className="rounded p-3" style={{ backgroundColor: '#eee' }}>
                {`<a title="${tooltipTxt}" href="" class="button" style="background: ${btnColor}; color: ${textColor}; padding: 4px 10px; border-radius: 6px; text-decoration: none; font-family: sans-serif;">${btnText}</a>`}
            </div>}
            <div className="mt-4 d-flex justify-content-end">
                <button
                    onClick={() => generateCode()}
                    className={`btn btn-dark`}
                >Generate code / Copy text</button>
            </div>
    </React.Fragment>
  );
};

export default ExtButton;
