export const FETCH_CONTRACTS = "FETCH_CONTRACTS";
export const SET_CONTRACTS = "SET_CONTRACTS";
export const FETCH_CONTRACT = "FETCH_CONTRACT";
export const SET_CONTRACT = "SET_CONTRACT";
export const ADD_CONTRACT = "ADD_CONTRACT";
export const EDIT_CONTRACT = "EDIT_CONTRACT";
export const DELETE_CONTRACT = "DELETE_CONTRACT";
export const CONTRACT_SUCCESS = "CONTRACT_SUCCESS";
export const CONTRACT_ERROR = "CONTRACT_ERROR";


