import { ADD_CONTRACT, DELETE_CONTRACT, EDIT_CONTRACT, FETCH_CONTRACT, FETCH_CONTRACTS, CONTRACT_ERROR, CONTRACT_SUCCESS, SET_CONTRACT, SET_CONTRACTS, SET_ROOM, SET_ROOMS, SET_TABLES, TABLE_ERROR, TABLE_SUCCESS } from "./actionTypes";

const initialState = {
  data: [],
  contract: null,
  loading: false,
  error: "",
  success: "",
};

const contract = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTRACTS:
      state = { ...state, loading: true };
      break;
    case SET_CONTRACTS:
      state = { ...state, loading: false, data: action.payload };
      break;
    case FETCH_CONTRACT:
        state = { ...state, loading: true };
        break;
    case SET_CONTRACT:
        state = { ...state, loading: false, contract: action.payload };
        break;
    case ADD_CONTRACT:
        state = { ...state, loading: true };
        break;
    case EDIT_CONTRACT:
      state = { ...state, loading: true };
      break;
    case DELETE_CONTRACT:
      state = { ...state, loading: true };
      break;
    case CONTRACT_SUCCESS:
      state = { ...state, success: action.payload, loading: false, };
      break;
    case CONTRACT_ERROR:
      state = { ...state, error: action.payload, loading: false, };
      break;
    default:
      state = { ...state, loading: false };
      break;
  }
  return state;
};

export default contract;
