import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { FETCH_RESERVATIONS } from "./actionTypes"
import { setReservations, reservationSuccess, reservationError } from "./actions"

//Include Both Helper File with needed methods
import { getReservations } from "../../helpers/backend_helper"


function* fetchReservations({ payload: data }) {
  try {
    const response = yield call(getReservations, data)
    yield put(setReservations(response))
    yield put(reservationSuccess(response))
  } catch (error) {
    yield put(reservationError(error))
  }
}

export function* watchReservation() {
  yield takeEvery(FETCH_RESERVATIONS, fetchReservations)
}

function* ReservationSaga() {
  yield all([fork(watchReservation)])
}

export default ReservationSaga;
