import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ADD_IMAGE, DELETE_IMAGE, EDIT_IMAGE, FETCH_IMAGE, FETCH_IMAGES } from "./actionTypes"
import { setImages, imageSuccess, imageError } from "./actions"

//Include Both Helper File with needed methods
import { deleteImage, getImages, postImage, putImage, uploadImage } from "../../helpers/backend_helper"


function* fetchImages() {
  try {
    const response = yield call(getImages)
    yield put(setImages(response))
    yield put(imageSuccess(response))
  } catch (error) {
    yield put(imageError(error))
  }
}

// function* fetchImage({ payload: data }) {
//   try {
//     const response = yield call(getImage, data)
//     yield put(setImage(response))
//     yield put(imageSuccess(response))
//   } catch (error) {
//     yield put(imageError(error))
//   }
// }

function* addImage({ payload: data }) {
  try {
    const fileInfo = yield call(postImage, data.formData)    
    yield call(uploadImage, {
      image: {
        name: fileInfo?.name,
        type: fileInfo?.type,
        size: fileInfo?.size,
        hash: fileInfo?.hash,
        path: fileInfo?.path,
        url: fileInfo?.url,
        uniqname: fileInfo?.uniqname,
      },
      media_order: 1,
      image_type: data.type,
    })
    
    const response = yield call(getImages)
    yield put(setImages(response))
    yield put(imageSuccess(response))
  } catch (error) {
    yield put(imageError(error))
  }
}

function* editImage({ payload: data }) {
  try {
     yield call(putImage, data)
    const response = yield call(getImages, data)
    yield put(setImages(response))
    yield put(imageSuccess(response))
  } catch (error) {
    yield put(imageError(error))
  }
}

function* removeImage({ payload: data }) {
  try {
     yield call(deleteImage, data)
    const response = yield call(getImages, data)
    yield put(setImages(response))
    yield put(imageSuccess(response))
  } catch (error) {
    yield put(imageError(error))
  }
}

export function* watchImage() {
  yield takeEvery(FETCH_IMAGES, fetchImages)
  // yield takeEvery(FETCH_IMAGE, fetchImage)
  yield takeEvery(ADD_IMAGE, addImage)
  yield takeEvery(EDIT_IMAGE, editImage)
  yield takeEvery(DELETE_IMAGE, removeImage)
}

function* ImageSaga() {
  yield all([fork(watchImage)])
}

export default ImageSaga;
