import React, { useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { Card, CardBody, CardSubtitle, CardTitle, Form, FormGroup, Input, Label } from "reactstrap";
import { postImage, uploadImage } from "../../helpers/backend_helper";

const Cover = () => {
    const [width, setWidth] = useState(100);
    const [height, setHeight] = useState(100);
    const [background, setBackground] = useState('#fff');
    const [selectedFiles, setselectedFiles] = useState([{ preview: 'https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png' }]);
    const [imgSrc, setImgSrc] = useState('');
    const [isCode, setIsCode] = useState(false);
    const codeRef = useRef();

    function handleAcceptedFiles(files) {
        files.map(file => {
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              formattedSize: formatBytes(file.size),
            })
            const formData = new FormData();
            formData.append('file', file);
            onUpload(formData)
        })
        setselectedFiles(files)
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    const onUpload = async file => {
        const image = await postImage(file)
        setImgSrc(`https://media.mymeal.ma${image?.url}`)
        console.log(`https://media.mymeal.ma${image?.url}`)
    }

    const generateCode = () => {
        setIsCode(true)
        const codeText = codeRef.current?.textContent || `<a href="" style="border-radius: 10px; overflow: hidden;"><img src="${imgSrc}" alt="generated-image" width="${width}" height="${height}"></a>`;
        navigator.clipboard.writeText(codeText)
        .then(() => {
            alert("Code copied to clipboard!");
        })
        .catch(err => {
            console.error('Failed to copy: ', err);
            alert('Failed to copy')
        });
    }
    
  return (
    <React.Fragment>
      <div className="position-relative d-flex align-items-center justify-content-center border rounded" style={{ height: 160, width: '100%', backgroundColor: background }}>
            <div className="position-absolute top-0 end-0 d-flex align-items-center mt-2 me-2">
                <button
                    onClick={() => setBackground('#fff')}
                    className={`btn btn-light p-2 rounded-circle me-2 shadow`}
                ></button>
                <button
                    onClick={() => setBackground('#eee')}
                    className={`btn btn-secondary p-2 rounded-circle shadow`}
                ></button>
            </div>
            <img
                src={imgSrc || selectedFiles[0].preview}
                width={width}
                height={height}
            />
      </div>
        <Label for="button-text" size="sm">Image file</Label>
        <Form
            className="dropzone"
            >
            <Dropzone
                onDrop={acceptedFiles => {
                handleAcceptedFiles(acceptedFiles)
                }}
            >
                {({ getRootProps, getInputProps }) => (
                <div style={{textAlign:"center"}}>
                    <div
                    className="dz-message needsclick"
                    {...getRootProps()}
                    >
                    <input {...getInputProps()} />
                    <div className="mb-3">
                    <i className="display-4 text-muted mdi mdi-cloud-upload-outline"></i>
                    </div>
                    <h4>Drop files here to upload</h4>
                    </div>
                </div>
                )}
            </Dropzone>
        </Form>
        <div className="d-flex align-items-center mt-3">
            <FormGroup className="flex-grow-1 me-3">
                <Label for="button-text" size="sm">Image width <span className="text-primary">{width}</span></Label>
                <Input placeholder="Width" min={100} max={800} className="border rounded" value={width} onChange={e => setWidth(e.target.value)} type="range" name="width" />
            </FormGroup>
            <FormGroup className="flex-grow-1 me-3">
                <Label for="tooltip-text" size="sm">Image height <span className="text-primary">{height}</span></Label>
                <Input placeholder="Height" min={100} max={800} className="border rounded" value={height} onChange={e => setHeight(e.target.value)} type="range" name="height" />
            </FormGroup>
        </div>
        {isCode && <div ref={codeRef} className="rounded p-3" style={{ backgroundColor: '#eee' }}>
            {`<a href="" style="border-radius: 10px; overflow: hidden;"><img src="${imgSrc}" alt="generated-image" width="${width}" height="${height}"></a>`}
        </div>}
        <div className="mt-4 d-flex justify-content-end">
            <button
                onClick={() => generateCode()}
                className={`btn btn-dark`}
            >Generate code / Copy text</button>
        </div>
    </React.Fragment>
  );
};

export default Cover;
