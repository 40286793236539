import React, { useState, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, CardTitle, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import "flatpickr/dist/themes/material_green.css";

import Flatpickr from "react-flatpickr";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { fetchReservations } from '../../store/reservation/actions';
import "flatpickr/dist/themes/material_green.css";
import Details from './details';
import _ from 'lodash';

const Reservations = () => {
  const [modal, setModal] = useState(false);
  const [current, setCurrent] = useState(null);
  const [q, setQ] = useState('');
  const dispatch = useDispatch();
  const reservations = useSelector(state => state.reservation.reservations)
  const loading = useSelector(state => state.reservation.loading)
  const [date, setDate] = useState(null)
  const [createDate, setCreateDate] = useState(null)
  const [sOpen, setSOpen] = useState(false);
//   const [page, setPage] = useState(1);
//   const [perPage, setPerPage] = useState(10);
  const [status, setStatus] = useState({ name: 'All statuses', id: null })
  const statuses = [
    { name: 'All statuses', id: null },
    { color: "#4a4c50", iconName: "mdi-cellphone-remove", id: 1, name: "Pas confirmé", status: 1 },
    { color: "#8451ca", iconName: "mdi-clock-outline", id: 22, name: "Liste d'attente", status: 2 },
    { color: "#b00020", iconName: "mdi-close-thick", id: 15, name: "Annulé", status: 6 },
    { color: "#999999", iconName: "mdi-check-bold", id: 13, name: "Terminé", status: 5 },
    { color: "#2f4858", iconName: "mdi-account-multiple-remove-outline", id: 16, name: "No show", status: 6 },
    { color: "#128849", iconName: "mdi-silverware-variant", id: 7, name: "Assise", status: 4 },
    { color: "#10b3ac", iconName: "mdi-pasta", id: 9, name: "Plat principal", status: 4 },
    { color: "#b00020", iconName: "mdi-minus-thick", id: 14, name: "Refusé", status: 6 },
  ]

  const toggleModal = data => {
    if (data) setCurrent(data)
    return setModal(!modal);
  }

  useEffect(() => {
    dispatch(fetchReservations({ search: q, date, created: createDate, status_id: status.id, /* page, perPage */ }))
  }, [date, createDate, status, /* page, perPage */])

  const debouncedSearch = useCallback(
      _.debounce((value) => {
        dispatch(fetchReservations({ search: value, date, created: createDate, status_id: status.id, /* page, perPage */ }))
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(q)
  }, [q])

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Ref</span>,
            selector: row => row.ref,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Status</span>,
            sortable: true,
            selector: (cell) => <span className="badge p-2 px-2" style={{ backgroundColor: cell.t_status_items.color }}><i className={`mdi me-1 ${cell.t_status_items.iconName}`}></i> {cell.t_status_items?.name} </span>,
        },
        {
            name: <span className='font-weight-bold fs-13'>Created at</span>,
            selector: row => row.created,
            sortable: true
        },
        
        {
            name: <span className='font-weight-bold fs-13'>Date</span>,
            selector: cell => {
              return (
                <>
                  <span className="font-size-12">{cell.resa_date}</span>
                  <p className="font-size-12 m-0 mt-1">{cell.resa_hour}</p>
                </>
              )
            },
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Client</span>,
            selector: row => row.t_user?.firstname + ' ' + row.t_user?.lastname,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Covers</span>,
            selector: row => row.nb_couvert,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            sortable: true,
            cell: (cell) => {
                return (
                    <>
                        <button onClick={() => toggleModal(cell)} className="btn btn-sm btn-secondary me-2 shadow text-nowrap px-3"><i className="ri-user-search-line align-bottom"></i></button>
                        <button onClick={() => {}} className="btn btn-sm btn-success me-2 shadow text-nowrap px-3"><i className="ri-message-line align-bottom"></i></button>
                    </>
                );
            },
        },
    ];

    return (
        <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="My restaurant" breadcrumbItem="Reservations" />
            { /* Tables */ }
            <Card>
              <CardBody>
              <Row>
                <Col md={6}>
                    <Flatpickr
                        value={date}
                        onChange={setDate}
                        data-enable-time
                        className="me-2"
                        placeholder="Search by creation date..."
                        style={{ padding: 7, borderTop: 0, borderRight: 0, borderLeft: 0, borderBottom: '2px solid #43a047', borderStyle: 'solid', borderRadius: 5 }}
                    />
                    <Flatpickr
                        value={createDate}
                        onChange={setCreateDate}
                        data-enable-time
                        className="me-2"
                        placeholder="Search by date..."
                        style={{ padding: 7, borderTop: 0, borderRight: 0, borderLeft: 0, borderBottom: '2px solid #43a047', borderStyle: 'solid', borderRadius: 5 }}
                    />
                </Col>
                <Col md={6}>
                    <div className="d-flex align-items-center justify-content-end">
                        <form className="app-search d-none d-lg-block p-0 me-2" style={{ width: '100%' }}>
                            <div className="position-relative">
                            <Input
                              value={q}
                              onChange={e => setQ(e.target.value)}
                              type="text"
                              debounce={500}
                              placeholder="Search by reference, client name, covers..."
                              name="q" />
                            <span className="ri-search-line"></span>
                            </div>
                        </form>
                        <Dropdown
                            isOpen={sOpen}
                            toggle={() => setSOpen(!sOpen)}
                            className="d-inline-block d-sm-inline-block"
                        >
                            <DropdownToggle className="btn btn-primary waves-effect" tag="button">
                            <span className="text-nowrap">
                                <i className={`mdi me-1 ${status.iconName}`}></i>
                                {status.name}
                                <i className="mdi ms-1 mdi-chevron-down"></i>
                            </span>
                            </DropdownToggle>
                            <DropdownMenu className="language-switch dropdown-menu-end">
                            {statuses.map(el => (
                                <DropdownItem
                                key={el.id}
                                onClick={() => setStatus(el)}
                                className={`notify-item ${ el.id === status.id ? "active" : "none" }`}
                            >
                                <i className={`mdi me-1 ${el.iconName} ${el.id === status.id && 'text-primary'}`}></i>
                                <span className={`align-middle ${el.id === status.id && 'text-primary'}`}>{el.name}</span>
                            </DropdownItem>
                            ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </Col>
            </Row>
            <DataTable
                columns={columns}
                data={reservations}
                pagination
                // paginationServer
                // paginationDefaultPage={page}
                // paginationPerPage={perPage}
                // onChangePage={setPage}
                // onChangeRowsPerPage={setPerPage}
                progressPending={loading}
            />

            <Modal
                isOpen={modal}
                toggle={() => { toggleModal() }}
                centered
                scrollable={true}
                size="lg"
                >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">
                    Reservation details
                    </h5>
                    <button
                    type="button"
                    onClick={() => { setModal(false) }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ maxHeight: 400 }}>
                    <Details info={current} />
                </div>
                <div className="modal-footer">
                  <button onClick={() => toggleModal()} class="btn btn-secondary">Close</button>
                </div>
            </Modal>
              </CardBody>
            </Card>
  
          </Container>
        </div>
      </React.Fragment>
    );
};

export default Reservations;