import React from 'react';
import LineColumnArea from '../Dashboard/LineColumnArea';

import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Row
} from "reactstrap";
import Dountchart from './DountChart';

const OverView = () => {
    return (
        <React.Fragment>
            <Row>
                <Col xl={8}>
                    <Card>
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <h5 className="card-title">Cover flow</h5>
                                </div>
                                <div className="flex-shrink-0">
                                    <div>
                                        <button type="button" className="btn btn-soft-secondary btn-sm me-1">
                                            ALL
                                        </button>
                                        <button type="button" className="btn btn-soft-primary btn-sm me-1">
                                            1M
                                        </button>
                                        <button type="button" className="btn btn-soft-secondary btn-sm me-1">
                                            6M
                                        </button>
                                        <button type="button" className="btn btn-soft-secondary btn-sm me-1 active">
                                            1Y
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <LineColumnArea />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={4}>
                    <Card>
                        <CardBody>
                            <CardTitle>Covers by shift</CardTitle>
                            <Dountchart />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default OverView;