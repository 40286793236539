import { ADD_INVOICE, DELETE_INVOICE, EDIT_INVOICE, FETCH_INVOICE, FETCH_INVOICES, INVOICE_ERROR, INVOICE_SUCCESS, SET_INVOICE, SET_INVOICES, SET_ROOM, SET_ROOMS, SET_TABLES, TABLE_ERROR, TABLE_SUCCESS } from "./actionTypes";

const initialState = {
  data: [],
  invoice: null,
  loading: false,
  error: "",
  success: "",
};

const invoice = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVOICES:
      state = { ...state, loading: true };
      break;
    case SET_INVOICES:
      state = { ...state, loading: false, data: action.payload };
      break;
    case FETCH_INVOICE:
        state = { ...state, loading: true };
        break;
    case SET_INVOICE:
        state = { ...state, loading: false, invoice: action.payload };
        break;
    case ADD_INVOICE:
        state = { ...state, loading: true };
        break;
    case EDIT_INVOICE:
      state = { ...state, loading: true };
      break;
    case DELETE_INVOICE:
      state = { ...state, loading: true };
      break;
    case INVOICE_SUCCESS:
      state = { ...state, success: action.payload, loading: false, };
      break;
    case INVOICE_ERROR:
      state = { ...state, error: action.payload, loading: false, };
      break;
    default:
      state = { ...state, loading: false };
      break;
  }
  return state;
};

export default invoice;
