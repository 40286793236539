import { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Nav, NavItem, Row, TabContent, TabPane, NavLink } from "reactstrap";
import classnames from "classnames";

const Approval = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [selected, setSelected] = useState({});
  const approvals = [
      {
        id: 1,
        title: 'Today',
        data: [
          { status: 'New reservation', date: '7-10-2004', hour: '17: 00', covers: 2, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
          { status: 'New reservation', date: '7-10-2004', hour: '17: 00', covers: 3, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
          { status: 'Canceled', date: '7-10-2004', hour: '17: 00', covers: 5, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
        ]
      },
      {
        id: 2,
        title: 'Tomorow',
        data: [
          { status: 'New reservation', date: '7-10-2004', hour: '17: 00', covers: 2, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
          { status: 'New reservation', date: '7-10-2004', hour: '17: 00', covers: 3, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
          { status: 'Canceled', date: '7-10-2004', hour: '17: 00', covers: 2, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
          { status: 'Canceled', date: '7-10-2004', hour: '17: 00', covers: 2, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
        ]
      },
      {
        id: 3,
        title: 'Next week',
        data: [
          { status: 'Canceled', date: '7-10-2004', hour: '17: 00', covers: 2, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
          { status: 'Canceled', date: '7-10-2004', hour: '17: 00', covers: 3, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
          { status: 'New reservation', date: '7-10-2004', hour: '17: 00', covers: 3, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
          { status: 'New reservation', date: '7-10-2004', hour: '17: 00', covers: 2, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
        ]
      },
      {
        id: 4,
        title: 'After next week',
        data: [
          { status: 'Canceled', date: '7-10-2004', hour: '17: 00', covers: 3, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
          { status: 'Canceled', date: '7-10-2004', hour: '17: 00', covers: 3, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
          { status: 'New reservation', date: '7-10-2004', hour: '17: 00', covers: 2, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
          { status: 'New reservation', date: '7-10-2004', hour: '17: 00', covers: 2, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
        ]
      },
  ]

    useEffect(() => {
      setSelected(approvals[0])
    }, [])

    useEffect(() => {
      console.log(selected)
    }, [activeTab])
    

    return (
        <Card>
          <CardBody>
            <CardTitle>Reservations</CardTitle>

            <div className="pe-3">
              <Row>
                <Col md="3">
                  <Nav
                    pills
                    className="nav flex-column nav-pills text-center"
                  >
                    {approvals.map(el => (
                      <NavItem key={el.id} className="py-1">
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === el.id,
                          })}
                          onClick={() => { setSelected(el); setActiveTab(el.id) }}
                        >
                          {el.title}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </Col>
                <Col md="9">
                        <TabContent
                          activeTab={activeTab}
                          className="text-muted mt-4 mt-md-0"
                        >
                          {selected?.data?.map((el, index) => (
                            <TabPane key={index} tabId={activeTab}>
                              <Row className="mb-3">
                                <Col md={6} sm={12}>
                                  <div className="d-flex align-items-center mb-1 align-items-center">
                                    <span className="badge badge-soft-secondary p-2 me-2"> {el.user?.firstname} </span>
                                    <span className="badge badge-soft-info p-2"> {el.status} </span>
                                  </div>
                                  <div className="d-flex align-items-center flex-wrap">
                                    <span className="d-flex align-items-center me-2 text-dark"> <i className="ri-calendar-line me-1"></i>{el.date} at <span className="text-nowrap ms-2">{el.hour}</span></span>
                                    <span className="d-flex align-items-center me-2 text-dark"> <i className="ri-restaurant-2-line me-1"></i>{el.covers}</span>
                                    <span className="d-flex align-items-center me-2 text-dark"> <i className="ri-time-line me-1"></i>Sent at {el.created}</span>
                                  </div>
                                </Col>
                                <Col md={6} sm={12}>
                                  <div className="d-flex justify-content-end align-items-center mt-2">
                                    <button type="button" className="btn btn-sm btn-light me-1"> <i className="ri-user-line"></i></button>
                                    <button type="button" className="btn btn-success btn-sm me-1"><i className="ri-check-fill"></i>Accept</button>
                                    <button type="button" className="btn btn-danger btn-sm me-1"><i className="ri-close-line"></i>Reject</button>
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                          ))}
                        </TabContent>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
    )
}
export default Approval;
