import { Col, Input, Row } from "reactstrap";
import { useDrag, useDrop } from "react-dnd";
const ItemType = "BOX";

const ElItem = ({ el, index, catIndex, moveEl, setElementName, deleteElement }) => {
    // Drag functionality
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Drop functionality
  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveEl(draggedItem.index, index, catIndex);
        draggedItem.index = index;
      }
    },
  });
  
  return (
        <Row>
            <Col cols={1}>
                <span ref={(node) => drag(drop(node))} className="d-flex justify-content-end" style={{ cursor: 'move', paddingRight: '10px', paddingLeft: '10px', paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold' }}>
                    <b>☰</b>
                </span>
            </Col>
            <Col md={4}>
                    <Input placeholder="Item name" value={el.name} onChange={e => setElementName(e.target.value, catIndex, catIndex)} type="text" name="name" className="mb-1" />
            </Col>
            <Col md={2}>
                    <Input placeholder="Price" value={el.price} onChange={e => setElementName(e.target.value, catIndex, index)} type="text" name="name" className="mb-1" />
            </Col>
            <Col md={4}>
                <div className="d-flex align-items-center">
                    <Input placeholder="Description" value={el.description} onChange={e => setElementName(e.target.value, catIndex, index)} type="textarea" rows={1} name="name" className="mb-1" />
                    <button onClick={() => deleteElement(catIndex, index)} type="button" className="btn btn-sm btn-outline-secondary ms-2">
                        X
                    </button>
                </div>
            </Col>
        </Row>
  )
}

export default ElItem;