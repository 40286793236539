import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Modal, Row, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addUser, editUser, fetchRoles, removeUser } from '../../store/actions';

const Users = ({ data }) => {
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState(4);
  const [password, setPassword] = useState(null);
  const [users, setUsers] = useState([]);

  const [q, setQ] = useState('');

  const [currentUser, setCurrentUser] = useState(null);

  const [modalType, setModalType] = useState('add');

  const dispatch = useDispatch();
  const roles = useSelector(state => state.restaurant.roles)

  const toggleModal = data => {
    setCurrentUser(data)
    setModalType(data ? 'edit' : 'add');
    setPassword('')
    setFirstname(data?.firstname || '')
    setLastname(data?.lastname || '')
    setEmail(data?.email || '')
    setPhone(data?.phone || '')
    setRole(data?.role || 4);

    return setModal(!modal);
  }

  const toggleModal1 = user => {

    setCurrentUser(user)
    setModal1(true)
  }
  const user = JSON.parse(localStorage.authUser)
  const restoId = user.resto

  const submitModal = () => {
    if (modalType === 'add') dispatch(addUser({ id: restoId, firstname, lastname, email, phone, role, password }))
    if(modalType === 'edit') dispatch(editUser({ id: currentUser.id, firstname, lastname, email, phone, role, password }))
    return setModal(false)
  }

  const deleteUser = () => {
    dispatch(removeUser(currentUser.id))
    return setModal1(false)
  }

  useEffect(() => {
    dispatch(fetchRoles())
  }, [])

  useEffect(() => {
    if (data && q) {
      const filteredUsers = data.filter(user => {
        // console.log(user)
        const fullName = `${user.firstname} ${user.lastname}`.toLowerCase();
        return fullName.includes(q.toLowerCase())
        // return setUsers(fullName.includes(q.toLowerCase()));
      })
      return setUsers(filteredUsers) 
    }
    else setUsers(data)
  }, [q, data?.length])
  
    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Firstname</span>,
            selector: row => row.firstname,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Lastname</span>,
            selector: row => row.lastname,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Contact</span>,
            selector: cell => {
              return (
                <>
                  <span className="font-size-12">{cell.email}</span>
                  <p className="font-size-12 m-0 mt-1">{cell.phone}</p>
                </>
              )
            },
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Role</span>,
            sortable: true,
            selector: (cell) => {
                switch (cell.t_role?.name) {
                    case "ROLE_RESTO_ADMIN":
                        return <span className="badge badge-soft-danger p-1"> {cell.t_role?.description} </span>;
                    case "ROLE_RESTO_MANAGER":
                        return <span className="badge badge-soft-info p-1"> {cell.t_role?.description} </span>;
                    default:
                        return <span className="badge badge-soft-secondary p-1"> {cell.t_role?.description} </span>;
                }
            },
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            sortable: true,
            cell: (cell) => {
                return (
                    <>
                        <button onClick={() => toggleModal(cell)} className="btn btn-sm me-2 shadow"><i className="ri-edit-line align-bottom text-dark"></i></button>
                        {cell.t_role.name !== 'ROLE_RESTO_ADMIN' && <button onClick={() => toggleModal1(cell)} className="btn btn-sm me-2 shadow"><i className="ri-delete-bin-line align-bottom text-danger"></i></button>}
                    </>
                );
            },
        },
    ];

    return (
        <>
            <Row>
                <Col md={6} />
                <Col md="6">
                <div className="d-flex align-items-center justify-content-end">
                  <form className="app-search d-none d-lg-block p-0">
                    <div className="position-relative">
                      <Input
                        value={q}
                        onChange={e => setQ(e.target.value)}
                        type="text"
                        placeholder="Search..."
                        name="q" />
                      <span className="ri-search-line"></span>
                    </div>
                  </form>
                  <Button color="primary" className="add-btn ms-2" onClick={() => { toggleModal() }} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add</Button>
                </div>
                </Col>
            </Row>
            <DataTable
                columns={columns}
                data={users}
                pagination
            />

            <Modal
              isOpen={modal}
              toggle={() => { toggleModal() }}
              centered
              scrollable={true}
              size="lg"
              >
              <div className="modal-header">
                  <h5 className="modal-title mt-0">
                  {modalType === 'add' ? 'Add user' : 'Update user'}
                  </h5>
                  <button
                  type="button"
                  onClick={() => { setModal(false) }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  >
                  <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div className="modal-body" style={{ maxHeight: 400 }}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitModal();
                    return false;
                  }}
                >
                  <Row>
                    <Col sm={12} lg={6}>
                      <FormGroup>
                        <Label for="firstname">Firstname</Label>
                        <Input value={firstname} onChange={e => setFirstname(e.target.value)} type="text" name="firstname" />
                      </FormGroup>
                    </Col>
                    <Col sm={12} lg={6}>
                      <FormGroup>
                        <Label for="lastname">Lastname</Label>
                        <Input value={lastname} onChange={e => setLastname(e.target.value)} type="text" name="lastname" />
                      </FormGroup>
                    </Col>
                    <Col sm={12} lg={6}>
                      <FormGroup>
                        <Label for="email">Email</Label>
                        <Input value={email} onChange={e => setEmail(e.target.value)} type="email" name="email" />
                      </FormGroup>
                    </Col>
                    <Col sm={12} lg={6}>
                      <FormGroup>
                        <Label for="phone">Phone</Label>
                        <Input value={phone} onChange={e => setPhone(e.target.value)} type="text" name="phone" />
                      </FormGroup>
                    </Col>
                    <Col sm={12} lg={6}>
                      <FormGroup>
                        <Label for="password">Password</Label>
                        <Input value={password} onChange={e => setPassword(e.target.value)} type="password" name="password" />
                      </FormGroup>
                    </Col>
                    <Col sm={12} lg={6}>
                      <FormGroup>
                        <Label for="role">Role</Label>
                        <Input
                          type="select"
                          name="role"
                          id="roleSelect"
                          value={role}
                          onChange={e => setRole(e.target.value)}
                        >
                          {roles.map(el => (
                            <option key={el.id} value={el.id} selected={role === el.id}>{el.description}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <button disabled={!firstname || !lastname || !email || !password} type="submit" class="btn btn-primary mt-4">{modalType === 'add' ? 'Add' : 'Save changes'}</button>
                </form>
              </div>
            </Modal>

            <Modal
              isOpen={modal1}
              toggle={() => { toggleModal1() }}
              centered
              >
              <div className="modal-header">
                  <h5 className="modal-title mt-0">
                  Delete user
                  </h5>
                  <button
                  type="button"
                  onClick={() => { setModal1(false) }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  >
                  <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div className="modal-body d-flex justify-content-center" style={{ maxHeight: 400 }}>
                <h5>Do you want to delete <strong>{currentUser?.firstname} {currentUser?.lastname}</strong>?</h5>
              </div>
              <div className="modal-footer d-flex justify-content-center">
              <button onClick={() => deleteUser()} type="submit" class="btn btn-danger px-4">Yes</button>
              <button onClick={() => setModal1(false)} type="submit" class="btn btn-secondary px-4">No</button>

              </div>
            </Modal>
        </>
    );
};

export default Users;