import { ADD_PROMOTION, DELETE_PROMOTION, EDIT_PROMOTION, FETCH_PROMOTION, FETCH_PROMOTIONS, PROMOTION_ERROR, PROMOTION_SUCCESS, SET_PROMOTION, SET_PROMOTIONS, SET_ROOM, SET_ROOMS, SET_TABLES, TABLE_ERROR, TABLE_SUCCESS } from "./actionTypes";

const initialState = {
  data: [],
  promotion: null,
  loading: false,
  error: "",
  success: "",
};

const promotion = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROMOTIONS:
      state = { ...state, loading: true };
      break;
    case SET_PROMOTIONS:
      state = { ...state, loading: false, data: action.payload };
      break;
    case FETCH_PROMOTION:
        state = { ...state, loading: true };
        break;
    case SET_PROMOTION:
        state = { ...state, loading: false, promotion: action.payload };
        break;
    case ADD_PROMOTION:
        state = { ...state, loading: true };
        break;
    case EDIT_PROMOTION:
      state = { ...state, loading: true };
      break;
    case DELETE_PROMOTION:
      state = { ...state, loading: true };
      break;
    case PROMOTION_SUCCESS:
      state = { ...state, success: action.payload, loading: false, };
      break;
    case PROMOTION_ERROR:
      state = { ...state, error: action.payload, loading: false, };
      break;
    default:
      state = { ...state, loading: false };
      break;
  }
  return state;
};

export default promotion;
