import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";


// Authentication
import forgetPassword from "./auth/forgetpwd/reducer";
import login from "./auth/login/reducer";
import profile from "./auth/profile/reducer";
import restaurant from "./resto/reducer";
import reservation from "./reservation/reducer";
import table from "./table/reducer";
import menu from './menu/reducer';
import invoice from "./invoice/reducer";
import promotion from "./promotion/reducer";
import image from "./image/reducer";
import contract from "./contract/reducer";


const rootReducer = combineReducers({
  // public
  Layout,
  forgetPassword,
  login,
  profile,
  restaurant,
  reservation,
  table,
  menu,
  invoice,
  promotion,
  image,
  contract,
});

export default rootReducer;
