export const FETCH_IMAGES = "FETCH_IMAGES";
export const SET_IMAGES = "SET_IMAGES";
// export const FETCH_IMAGE = "FETCH_IMAGE";
// export const SET_IMAGE = "SET_IMAGE";
export const ADD_IMAGE = "ADD_IMAGE";
export const EDIT_IMAGE = "EDIT_IMAGE";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const IMAGE_SUCCESS = "IMAGE_SUCCESS";
export const IMAGE_ERROR = "IMAGE_ERROR";


