import React from "react";
import { useDrag, useDrop } from "react-dnd";

const ItemType = "IMAGE";

const ImageComponent = ({ image, index, moveImage }) => {
  // Drag functionality
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Drop functionality
  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveImage(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
        margin: "10px",
        border: "1px solid #ddd",
      }}
    >
      <img src={image} alt={`Image ${index}`} className="rounded" width="150" height="150" />
    </div>
  );
};

export default ImageComponent;
