import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormFeedback, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import { addPromotion, editPromotion, fetchMenus, fetchResto } from "../../store/actions";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import "flatpickr/dist/themes/material_green.css";
import { getLoggedInUser } from "../../helpers/backend_helper";

const Details = ({ info, onChange }) => {
    const [name, setName] = useState(null);
    const [type, setType] = useState('P');
    const [menu, setMenu] = useState(null);
    const [sDate, setSDate] = useState(null);
    const [eDate, setEDate] = useState(null);
    const [discount, setDiscount] = useState(null);
    const [shift, setShift] = useState(null);
    const [covers, setCovers] = useState(null);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [isCovers, setIsCovers] = useState(false);
    const [isMenu, setIsMenu] = useState(false);
    const [isShift, setIsShift] = useState(false);
    const [days, setDays] = useState([]);
    const promotion = useSelector(state => state.promotion?.promotion)
    const menus = useSelector(state => state.menu?.data || [])
    const shifts = useSelector(state => state.restaurant.resto?.t_shift);
    const user = getLoggedInUser();

    const types = [
        { name: 'Percentage', value: 'P' },
        { name: 'MIAMS', value: 'M' },
        { name: 'Percentage & MIAMS', value: 'PM' },
    ]

    const daysO = [
        { name: 'Monday', value: 0 },
        { name: 'Tuesday', value: 1 },
        { name: 'Wednesday', value: 2 },
        { name: 'Tuesday', value: 3 },
        { name: 'Friday', value: 4 },
        { name: 'Saturday', value: 5 },
        { name: 'Sunday', value: 6 },
    ]

    const dispatch = useDispatch();

    function handleMulti(selected) {
        setShift(selected);
      }

    useEffect(() => {
        dispatch(fetchResto())
        dispatch(fetchMenus())
    }, [])

    useEffect(() => {
        setName(info?.name)
        setDiscount(info?.promo_value)
        if (info?.menu) {
            setIsMenu(true)
            setMenu(info.menu)
        }
        if (info?.shifts && info.shifts.length > 0) {
            setIsShift(true)
            const shs = shifts.filter(el => info.shifts.includes(el.id))
            setShift(shs)
        }
        if (info?.couvert) {
            setIsCovers(true)
            setCovers(info.couvert)
        }
        setSDate(info?.date_from)
        setEDate(info?.date_to)
        setFrom(info?.promo_start_hour)
        setTo(info?.promo_end_hour)
        if (info?.days) {
            const rdays = info.days.split(',')
            rdays.forEach((el, index) => el === '1' && days.push(index))
        }
    }, [info])

    const setTime = (date, fromOrTo) => {
        if (date.length > 0) {
          // Format to "HH:MM" using JavaScript's Date methods
          const hours = date[0].getHours().toString().padStart(2, '0');
          const minutes = date[0].getMinutes().toString().padStart(2, '0');
          if (fromOrTo === 'from') return setFrom(`${hours}:${minutes}`);
          if (fromOrTo === 'to') return setTo(`${hours}:${minutes}`);
        }
    };

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
          // Add selected option
          setDays([...days, parseInt(value)]);
        } else {
          // Remove unselected option
          setDays(days.filter((option) => option !== parseInt(value)));
        }
    };

    const submitChanges = () => {
        const array = daysO.map(el => (days.includes(el.value) ? '1' : '0'))
        const payload = {
            name,
            promo_value_type: type,
            menu: menu && menu.id,
            date_from: sDate,
            date_to: eDate,
            promo_value: discount && discount.toString(),
            shifts: shifts?.map(el => el.id),
            couvert: covers && covers.toString() || "0",
            promo_start_hour: from,
            promo_end_hour: to,
            days: array && array.join(','),
            resto: user.resto,
        }
        if (info) payload.id = info.id
        if (info) {
            dispatch(editPromotion(payload))
            return onChange()
        }
        dispatch(addPromotion(payload))
        return onChange()

    }
    
    const getEmblem = () => {
        if (type === 'P') return "%"
        if (type === 'M') return "🎟️"
        if (type === 'PM') return '% 🎟️'
        return ''
    }

    const getDiscountPlaceholder = () => {
        if (type === 'P') return "Percentage"
        if (type === 'M') return "MIAMS"
        if (type === 'PM') return 'Percentage and MIAMS'
        return ''
    }

    const changeSdate = (date) => {
    // Format the date using toISOString to get yyyy-mm-dd format
    const formattedDate = date[0].toISOString().slice(0, 10); 
    setSDate(formattedDate);
    };

    const changeEdate = (date) => {
        // Format the date using toISOString to get yyyy-mm-dd format
        const formattedDate = date[0].toISOString().slice(0, 10); 
        setEDate(formattedDate);
        };

    return (
        <>
            <form onSubmit={(e) => {
                    e.preventDefault();
                    submitChanges()
                    return false;
                }}
            >
                <Row>
                    <Col md={6} className="border-bottom mb-2">
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Input value={name} onChange={e => setName(e.target.value)} type="text" name="name" />
                        </FormGroup>
                    </Col>
                    <Col md={6} className="border-bottom mb-2">
                        <Label for="menu">Discount <span className="text-primary">{getEmblem()}</span></Label>
                        <Input value={discount} placeholder={getDiscountPlaceholder()} onChange={e => setDiscount(e.target.value)} type="number" name="discount" />
                        <div className="d-flex align-items-center flex-wrap mt-1">
                            {types.map((el, index) => (
                                <FormGroup check className="me-2" key={index}>
                                    <Label check size="sm">
                                        <Input type="radio" checked={type === el.value} onChange={e => setType(e.target.value)} value={el.value} name={el.value} />{' '}
                                        {el.name}
                                    </Label>
                                </FormGroup>
                            ))}
                        </div>
                    </Col>
                    <Col md={4} className="border-bottom mb-2">
                        <FormGroup>
                            {/* <Label for="menu">Menu</Label> */}
                            <FormGroup check className="me-2">
                                <Label check>
                                    <Input type="checkbox" checked={isMenu} onChange={() => setIsMenu(!isMenu)} value={isMenu} />{' '}
                                    Menu
                                </Label>
                            </FormGroup>
                            {isMenu && <Select
                                value={menu}
                                isDisabled={!isMenu}
                                onChange={val => {
                                    setMenu(val);
                                }}
                                options={menus}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                                classNamePrefix="select2-selection"
                            />}
                        </FormGroup>
                    </Col>
                    <Col md={4} className="border-bottom mb-2">
                        <FormGroup>
                            <FormGroup check className="me-2">
                                <Label check>
                                    <Input type="checkbox" checked={isCovers} onChange={() => setIsCovers(!isCovers)} value={isCovers} />{' '}
                                    Covers
                                </Label>
                            </FormGroup>
                            {/* <Label for="covers">Covers</Label> */}
                            {isCovers && <Input disabled={!isCovers} placeholder="covers" value={covers} onChange={e => setCovers(e.target.value)} type="number" name="covers" />}
                        </FormGroup>
                    </Col>
                    <Col md={4} className="border-bottom mb-2">
                        <FormGroup>
                            <FormGroup check className="me-2">
                                <Label check>
                                    <Input type="checkbox" checked={isShift} onChange={() => setIsShift(!isShift)} value={isShift} />{' '}
                                    Shift
                                </Label>
                            </FormGroup>
                            {/* <Label for="shift">Shift</Label> */}
                            {isShift && <Select
                                value={shift}
                                isDisabled={!isShift}
                                isMulti={true}
                                onChange={() => {
                                    handleMulti();
                                }}
                                options={shifts}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                                classNamePrefix="select2-selection"
                            />}
                        </FormGroup>
                    </Col>
                    <Col md={6} className="mb-2">
                        <Label for="menu" className="d-block">Start date</Label>
                        <Flatpickr
                            value={sDate}
                            onChange={changeSdate}
                            className="me-2"
                            placeholder="Date"
                            style={{ padding: 7, borderTop: 0, borderRight: 0, borderLeft: 0, borderBottom: '2px solid #43a047', borderStyle: 'solid', borderRadius: 5, width: '100%' }}
                        />
                    </Col>
                    <Col md={6} className="mb-2">
                        <Label for="menu" className="d-block">End date</Label>
                        <Flatpickr
                            value={eDate}
                            options={{
                                minDate: sDate,
                            }}
                            onChange={changeEdate}
                            className="me-2"
                            placeholder="Date"
                            style={{ padding: 7, borderTop: 0, borderRight: 0, borderLeft: 0, borderBottom: '2px solid #43a047', borderStyle: 'solid', borderRadius: 5, width: '100%' }}
                        />
                    </Col>
                    <Col md={6} className="border-bottom mb-2">
                        <FormGroup>
                            <Label for="from" className="d-block">From</Label>
                            <Flatpickr
                            value={from}
                            onChange={val => setTime(val, 'from')}
                            options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: 'H:i', // Format to only show hour and minute
                                time_24hr: true // Use 24-hour time format
                            }}
                            style={{ padding: 7, borderStyle: 'solid', borderRadius: 5, borderColor: '#eff2f7', width: '100%' }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6} className="border-bottom mb-2">
                        <FormGroup>
                            <Label for="to" className="d-block">To</Label>
                            <Flatpickr
                            value={to}
                            onChange={val => setTime(val, 'to')}
                            options={{
                                minDate: from,
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: 'H:i', // Format to only show hour and minute
                                time_24hr: true // Use 24-hour time format
                            }}
                            style={{ padding: 7, borderStyle: 'solid', borderRadius: 5, borderColor: '#eff2f7', width: '100%' }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={12} className="border-bottom mb-2">
                        <Label for="days" className="d-block">Days</Label>
                        <div className="d-flex align-items-center flex-wrap mt-1">
                            {daysO.map((el, index) => (
                                <FormGroup check className="me-2" key={index}>
                                    <Label check>
                                        <Input type="checkbox" checked={days.includes(el.value)} onChange={handleCheckboxChange} value={el.value} name={el.value} />{' '}
                                        {el.name}
                                    </Label>
                                </FormGroup>
                            ))}
                        </div>
                    </Col>
                </Row>
                <div className="d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary mt-4">Save changes</button>
                </div>
            </form>
        </>
    )
}

export default Details;