import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Col, FormGroup, Input, Label, Modal, Row, UncontrolledAccordion } from 'reactstrap';
import { addShift, editShift, removeShift } from '../../store/actions';
import "flatpickr/dist/themes/material_green.css";

import Flatpickr from "react-flatpickr";

const Shifts = ({ data }) => {
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [name, setName] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [q, setQ] = useState('');
  const [shifts, setShifts] = useState([]);

  const [schedule, setSchedule] = useState([
    {
      day: 'Monday',
      shifts: [
        { from: '8:00', to: '11:30' },
        { from: '12:00', to: '16:30' },
        { from: '17:00', to: '00:00' },
      ]
    },
    {
      day: 'Tuesday',
      shifts: [
        { from: null, to: null },
        { from: null, to: null },
        { from: null, to: null },
      ]
    },
    {
      day: 'Wednesday',
      shifts: [
        { from: null, to: null },
        { from: null, to: null },
        { from: null, to: null },
      ]
    },
    {
      day: 'Thursday',
      shifts: [
        { from: null, to: null },
        { from: null, to: null },
        { from: null, to: null },
      ]
    },
    {
      day: 'Friday',
      shifts: [
        { from: null, to: null },
        { from: null, to: null },
        { from: null, to: null },
      ]
    },
    {
      day: 'Saturday',
      shifts: [
        { from: null, to: null },
        { from: null, to: null },
        { from: null, to: null },
      ]
    },
    {
      day: 'Sunday',
      shifts: [
        { from: null, to: null },
        { from: null, to: null },
        { from: null, to: null },
      ]
    },
  ])

  const [current, setCurrent] = useState(null);

  const [modalType, setModalType] = useState('add');

  const dispatch = useDispatch();

  const [open, setOpen] = useState(1);
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const toggleModal = data => {
    setCurrent(data)
    setModalType(data ? 'edit' : 'add');
    setName(data?.name || '')
    setFrom(data?.hour_begin || '')
    setTo(data?.hour_end || '')

    return setModal(!modal);
  }

  const toggleModal1 = data => {
    setCurrent(data)
    setModal1(true)
  }

  const user = JSON.parse(localStorage.authUser)
  const restoId = user.resto

  const submitModal = () => {
    if (modalType === 'add') dispatch(addShift({ id: restoId, name, hourB: from, hourE: to }))
    if (modalType === 'edit') dispatch(editShift({ id: current.id, name, hourB: from, hourE: to }))
    return setModal(false)
  }

  const deleteShift = () => {
    dispatch(removeShift(current.id))
    return setModal1(false)
  }

  const setTime = (date, fromOrTo, dayIndex, shiftIndex) => {
    const [...newSchedule] = schedule
    if (date.length > 0) {
      // Format to "HH:MM" using JavaScript's Date methods
      const hours = date[0].getHours().toString().padStart(2, '0');
      const minutes = date[0].getMinutes().toString().padStart(2, '0');
      if (fromOrTo === 'from') {
        newSchedule[dayIndex].shifts[shiftIndex].from = `${hours}:${minutes}`
        return setSchedule(newSchedule)
      }
      if (fromOrTo === 'to') {
        newSchedule[dayIndex].shifts[shiftIndex].to = `${hours}:${minutes}`
        return setSchedule(newSchedule)
      }
    }
  };

  useEffect(() => {
    if (data && q) {
      const filteredShifts = data.filter(el => {
        const name = el.name.toLowerCase();
        return name.includes(q.toLowerCase())
      })
      return setShifts(filteredShifts) 
    }
    else setShifts(data)
  }, [q, data?.length])

    return (
        <>
            <UncontrolledAccordion flush stayOpen>
                    {schedule.map((el, index) => (
                      <AccordionItem>
                        <AccordionHeader targetId={index + 1}>
                          <div className="d-flex align-items-center justify-content-between flex-wrap" style={{ width: '95%' }}>
                            <b className="flex-grow-1">{el.day}</b>
                            {!el.shifts.some(el => el.from) && <span className="badge badge-soft-secondary me-2 p-2">Closed</span>}
                            <div>
                              {el.shifts[0].from && el.shifts[0].to && <span className="badge badge-soft-success warning me-3 p-2">{el.shifts[0].from} to {el.shifts[0].to}</span>}
                              {el.shifts[1].from && el.shifts[1].to && <span className="badge badge-soft-warning warning me-3 p-2">{el.shifts[1].from} to {el.shifts[1].to}</span>}
                              {el.shifts[2].from && el.shifts[2].to && <span className="badge badge-soft-info warning p-2">{el.shifts[2].from} to {el.shifts[2].to}</span>}

                            </div>
                          </div>
                        </AccordionHeader>
                        <AccordionBody accordionId={index + 1}>
                        {el.shifts.map((shift, shIndex) => (
                          <FormGroup>
                            <Row>
                              <Col md={4} className='my-auto'>
                                <Label for="morning" className="d-block">shift {shIndex + 1}</Label>
                              </Col>
                              <Col>
                                <div className="d-flex align-items-center">
                                  <Flatpickr
                                    value={shift.from}
                                    onChange={val => setTime(val, 'from', index, shIndex)}
                                    className="me-2"
                                    placeholder='From'
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: 'H:i', // Format to only show hour and minute
                                      time_24hr: true // Use 24-hour time format
                                    }}
                                    style={{ padding: 7, borderStyle: 'solid', borderRadius: 5, borderColor: '#eff2f7', width: '100%' }}
                                  />
                                  <Flatpickr
                                    value={shift.to}
                                    onChange={val => setTime(val, 'to', index, shIndex)}
                                    placeholder='To'
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: 'H:i', // Format to only show hour and minute
                                      time_24hr: true // Use 24-hour time format
                                    }}
                                    style={{ padding: 7, borderStyle: 'solid', borderRadius: 5, borderColor: '#eff2f7', width: '100%' }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </FormGroup>
                        ))}
                        </AccordionBody>
                      </AccordionItem>
                    ))}
                  </UncontrolledAccordion>
            <Modal
                isOpen={modal}
                toggle={() => { toggleModal() }}
                centered
                scrollable={true}
                size="lg"
                >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">
                    {modalType === 'add' ? 'Add user' : 'Update shift'}
                    </h5>
                    <button
                    type="button"
                    onClick={() => { setModal(false) }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ maxHeight: 400 }}>
                  <h4>Schedule</h4>
                  
                </div>
            </Modal>

            <Modal
              isOpen={modal1}
              toggle={() => { toggleModal1() }}
              centered
              >
              <div className="modal-header">
                  <h5 className="modal-title mt-0">
                  Delete shift
                  </h5>
                  <button
                  type="button"
                  onClick={() => { setModal1(false) }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  >
                  <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div className="modal-body d-flex justify-content-center" style={{ maxHeight: 400 }}>
                <h5>Do you want to delete <strong>{current?.name}</strong> shift?</h5>
              </div>
              <div className="modal-footer d-flex justify-content-center">
              <button onClick={() => deleteShift()} type="submit" class="btn btn-danger px-4">Yes</button>
              <button onClick={() => setModal1(false)} type="submit" class="btn btn-secondary px-4">No</button>

              </div>
            </Modal>
        </>
    );
};

export default Shifts;