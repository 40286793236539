import React from 'react';

import img1 from '../assets/images/mm-dark.png'
import img2 from '../assets/images/mm-logo.png'

import { Container, Row, Col, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';




const Maintenance = () => {
    document.title = "Maintenance  | Mymeal";

    return (
        <React.Fragment>
            <div className="py-5">
            
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={6} md={8} xl={5}>
                            <div className="text-center mt-sm-5 mb-4">
                                <Link to="/" className="">
                                    <img src={img1} alt="" height="36" className="auth-logo logo-dark mx-auto" />
                                    <img src={img2} alt="" height="36" className="auth-logo logo-light mx-auto" />
                                </Link>

                                <div className="mt-5">
                                    <div className="mb-4">
                                        <i className="ri-tools-fill display-3"></i>
                                    </div>
                                    <h4>Site is Under Maintenance</h4>
                                    <p>Please check back in sometime</p>

                                    <div className="mt-4 pt-2">
                                        <Link to="/" className="btn btn-primary">Back to Home</Link>
                                    </div>

                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>

            </div>
        </React.Fragment>
    )
}

export default Maintenance;
