export const FETCH_INVOICES = "FETCH_INVOICES";
export const SET_INVOICES = "SET_INVOICES";
export const FETCH_INVOICE = "FETCH_INVOICE";
export const SET_INVOICE = "SET_INVOICE";
export const ADD_INVOICE = "ADD_INVOICE";
export const EDIT_INVOICE = "EDIT_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const INVOICE_SUCCESS = "INVOICE_SUCCESS";
export const INVOICE_ERROR = "INVOICE_ERROR";


