import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import { addRoom, editRoom, removeRoom } from "../../store/actions";
import logo from "../../assets/images/mm-dark.png";

const Details = ({ info, shapes }) => {
    const [method, setMethod] = useState(null)
    const [modal, setModal] = useState(false);
    const invoice = useSelector(state => state.invoice?.invoice)

    const dispatch = useDispatch();

    const download = () => {
        console.log('download')
    }

    const print = () => {
        console.log('Pring')
    }

    return (
        <>
           <div className="d-flex align-items-center justify-content-between border-bottom py-2">
                <div>
                    <img
                        src={logo}
                        alt=""
                        height="56"
                      />
                    <h2 className="my-4">Facture #{'info?.ref'}</h2>
                </div>
                <div>
                    <span className="badge p-2 px-2">{'info?.status'}</span>
                    <span className="d-block">{'info?.data'}</span>
                    <button className="btn btn-sm btn-primary me-2 shadow text-nowrap px-3">
                        Pay now
                    </button>
                </div>
           </div>
           <div className="d-flex align-items-center justify-content-between">
                <div>
                    <h5>Invoiced at:</h5>
                    <span className="d-block mb-2">{'info?.resto'}</span>
                    <span className="d-block mb-2">{'info?.firstname'} {'info?.lastname'}</span>
                    <span className="d-block mb-2">{'info?.address'}</span>
                    <span className="d-block mb-2">{'info?.zipcode'}</span>
                    <span className="d-block mb-2">{'info?.city'}</span>
                </div>
                <div>
                    <h5>Payed at:</h5>
                    <span className="d-block mb-2">{'info?.resto'}</span>
                    <span className="d-block mb-2">{'info?.firstname'} {'info?.lastname'}</span>
                    <span className="d-block mb-2">{'info?.address'}</span>
                    <span className="d-block mb-2">{'info?.zipcode'}</span>
                    <span className="d-block mb-2">{'info?.city'}</span>
                </div>
           </div>
           <div className="d-flex align-items-center justify-content-between">
                <div>
                    <h5>Execution date:</h5>
                    <span>{'info?.ex_date'}</span>
                </div>
                <div>
                    <h5>Payment method:</h5>
                    <select className="form-control">
                        <option value={null} selected={!method}>Select method</option>
                        <option value="card" selected={method === 'card'}>Card</option>
                        <option value="cash" selected={method === 'cash'}>Cash</option>
                    </select>
                </div>
           </div>
            <h3>Invoice items</h3>
           <div className="row mb-3 d-flex justify-content-center">
                <div className="col-8 border border-right">
                    <b className="d-block mb-2">Description</b>
                    <span>{'info?.description'}</span>
                </div>
                <div className="col-auto border border-right">
                    <b className="d-block mb-2">Total</b>
                    <span>{'info?.moment'}</span>
                </div>
           </div>

           <div className="row d-flex justify-content-center">
                <div className="col-4 border border-right">
                    <b className="d-block mb-2">Transaction date</b>
                    <span>{'info?.description'}</span>
                </div>
                <div className="col-2 border border-right">
                    <b className="d-block mb-2">Gateway</b>
                    <span>{'info?.gateway'}</span>
                </div>
                <div className="col-2 border border-right">
                    <b className="d-block mb-2">Transaction</b>
                    <span>{'info?.transaction'}</span>
                </div>
                <div className="col-auto border border-right">
                    <b className="d-block mb-2">Total</b>
                    <span>{'info?.total'}</span>
                </div>
           </div>
           <div className="d-flex justify-content-end my-4">
                <b className="me-2">Total: </b>
                <span>{'info?.total'}</span>
            </div>
           <div className="d-flex align-items-center justify-content-end">
            <button onClick={() => download()} className="btn btn-sm btn-primary me-2 shadow text-nowrap px-3">
                Print
            </button>
            <button onClick={() => print()} className="btn btn-sm btn-primary me-2 shadow text-nowrap px-3">
                Download
            </button>
           </div>

        </>
    )
}

export default Details;