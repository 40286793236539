import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ADD_PROMOTION, DELETE_PROMOTION, EDIT_PROMOTION, FETCH_PROMOTION, FETCH_PROMOTIONS } from "./actionTypes"
import { setPromotions, setPromotion, promotionSuccess, promotionError } from "./actions"

//Include Both Helper File with needed methods
import { deletePromotion, getPromotion, getPromotions, postPromotion, putPromotion } from "../../helpers/backend_helper"


function* fetchPromotions({ payload: data }) {
  try {
    const response = yield call(getPromotions, data)
    yield put(setPromotions(response))
    yield put(promotionSuccess(response))
  } catch (error) {
    yield put(promotionError(error))
  }
}

function* fetchPromotion({ payload: data }) {
  try {
    const response = yield call(getPromotion, data)
    yield put(setPromotion(response))
    yield put(promotionSuccess(response))
  } catch (error) {
    yield put(promotionError(error))
  }
}

function* addPromotion({ payload: data }) {
  try {
     yield call(postPromotion, data)
    const response = yield call(getPromotions, data)
    yield put(setPromotions(response))
    yield put(promotionSuccess(response))
  } catch (error) {
    yield put(promotionError(error))
  }
}

function* editPromotion({ payload: data }) {
  try {
     yield call(putPromotion, data)
    const response = yield call(getPromotions, data)
    yield put(setPromotions(response))
    yield put(promotionSuccess(response))
  } catch (error) {
    yield put(promotionError(error))
  }
}

function* removePromotion({ payload: data }) {
  try {
     yield call(deletePromotion, data)
    const response = yield call(getPromotions, data)
    yield put(setPromotions(response))
    yield put(promotionSuccess(response))
  } catch (error) {
    yield put(promotionError(error))
  }
}

export function* watchPromotion() {
  yield takeEvery(FETCH_PROMOTIONS, fetchPromotions)
  yield takeEvery(FETCH_PROMOTION, fetchPromotion)
  yield takeEvery(ADD_PROMOTION, addPromotion)
  yield takeEvery(EDIT_PROMOTION, editPromotion)
  yield takeEvery(DELETE_PROMOTION, removePromotion)
}

function* PromotionSaga() {
  yield all([fork(watchPromotion)])
}

export default PromotionSaga;
