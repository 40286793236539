import { AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import Details from "./details";
import { useDrag, useDrop } from "react-dnd";
const ItemType = "BOX";

const MenuItem = ({ el, index, moveMenu, toggleModal1 }) => {
    // Drag functionality
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Drop functionality
  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveMenu(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });
  return (
    <AccordionItem
        style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
        margin: "10px",
        border: "1px solid #ddd",
        }}
    >
            <AccordionHeader targetId={index + 1}>
                <span ref={(node) => drag(drop(node))} style={{ cursor: 'move', paddingRight: '10px', paddingLeft: '10px', paddingTop: '5px', paddingBottom: '5px', fontWeight: 'bold' }}>
                    <b>☰</b>
                </span>
                <div className="flex-grow-1 d-flex row">
                <b className="col-4">{el.name}</b>
                <span className="col-4 ms-4">{el.avrg_price} Average price</span>
                </div>
                <button disabled={el.t_menu_category?.length > 0} onClick={() => toggleModal1(el)} className="btn btn-sm btn-danger me-2 shadow text-nowrap px-3"><i className="ri-delete-bin-line align-bottom"></i></button>
            </AccordionHeader>
            <AccordionBody accordionId={index + 1}>
                <Details info={el} isDetails={true} />
            </AccordionBody>
    </AccordionItem>
  )
}

export default MenuItem;