import React from "react";

import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import OverView from "./OverView";
import Dountchart from './DountChart';
import PieChart from './PieChart';
import ColumnWithDataLabels from './ColumnWithDataLabels';

const Rapports = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Mymeal" breadcrumbItem="Rapports" />
          {/* User Panel Charts */}
          <OverView />
          <Row>
                <Col lg={4}>
                    <Card>
                        <CardBody>
                            <CardTitle>Channel of reservation</CardTitle>
                            <Dountchart />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <CardBody>
                            <CardTitle>Walk-in vs Reservation</CardTitle>
                            <Dountchart />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <CardBody>
                            <CardTitle>Reservation status</CardTitle>
                            <Dountchart />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={9}>
                    <Card>
                        <CardBody>
                            <CardTitle>Performance</CardTitle>
                            <ColumnWithDataLabels />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={3}>
                    <Card>
                        <CardBody>
                            <CardTitle>Cover source</CardTitle>
                            <PieChart />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Rapports;
