import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG, FETCH_PROFILE, SET_PROFILE, EDIT_PASSWORD, EDIT_EMAIL } from "./actionTypes"

export const fetchProfile = () => {
  return {
    type: FETCH_PROFILE,
  }
}

export const setProfile = user => {
  return {
    type: SET_PROFILE,
    payload: user,
  }
}

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const editEmail = user => {
  return {
    type: EDIT_EMAIL,
    payload: { user },
  }
}

export const editPassword = user => {
  return {
    type: EDIT_PASSWORD,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}
