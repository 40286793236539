// Resto
export const FETCH_RESTO = "FETCH_RESTO";
export const EDIT_RESTO_INFO = "EDIT_RESTO_INFO"
export const EDIT_RESTO_CONTACT = "EDIT_RESTO_CONTACT"
export const EDIT_RESTO_ADDRESS = "EDIT_RESTO_ADDRESS"
export const RESTO_SUCCESS = "RESTO_SUCCESS"
export const RESTO_ERROR = "RESTO_ERROR"
export const SET_RESTO = "SET_RESTO";
export const FETCH_KITCHEN = "FETCH_KITCHEN";
export const SET_KITCHEN = "SET_KITCHEN";

// Users
export const ADD_USER = "ADD_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const FETCH_ROLES = "FETCH_ROLES";
export const SET_ROLES = "SET_ROLES";

// Shifts
export const ADD_SHIFT = "ADD_SHIFT";
export const EDIT_SHIFT = "EDIT_SHIFT";
export const DELETE_SHIFT = "DELETE_SHIFT";

// Kitchens
export const ADD_KITCHEN = "ADD_KITCHEN";
export const DELETE_KITCHEN = "DELETE_KITCHEN";

// Configuration
export const EDIT_CONFIG = "EDIT_CONFIG";
