import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_RESTO_INFO, FETCH_RESTO, EDIT_RESTO_ADDRESS, FETCH_KITCHEN, EDIT_RESTO_CONTACT, FETCH_ROLES, ADD_USER, EDIT_USER, DELETE_USER, ADD_SHIFT, EDIT_SHIFT, DELETE_SHIFT, ADD_KITCHEN, DELETE_KITCHEN, EDIT_CONFIG } from "./actionTypes"
import { setResto, restoSuccess, restoError, setKitchen, setRoles } from "./actions"

//Include Both Helper File with needed methods
import { deleteKitchen, deleteRestoUser, deleteShift, getKitchen, getResto, getRoles, postKitchen, postRestoUser, postShift, putRestoAddress, putRestoContact, putRestoInfo, putRestoUser, putShift } from "../../helpers/backend_helper"


function* fetchResto() {
  try {
    const response = yield call(getResto)
    yield put(setResto(response))
    yield put(restoSuccess(response))
  } catch (error) {
    yield put(restoError(error))
  }
}

function* fetchKitchen() {
  try {
    const response = yield call(getKitchen)
    yield put(setKitchen(response))
    yield put(restoSuccess(response))
  } catch (error) {
    yield put(restoError(error))
  }
}

function* editRestoInfo({ payload: { data } }) {
  try {
    yield call(putRestoInfo, {
      id: data.id,
      name: data.name,
      description: data.description,
    })
    const response = yield call(getResto)
    yield put(setResto(response))
    yield put(restoSuccess(response))
  } catch (error) {
    yield put(restoError(error))
  }
}

function* editRestoContact({ payload: { data } }) {
  try {
    yield call(putRestoContact,
      [
        { key: "owner_firstname", val: data.firstname },
        { key: "owner_lastname", val: data.lastname },
        { key: "owner_email", val: data.email },
        { key: "owner_phone", val: data.phone },
      ]
    )
    const response = yield call(getResto)
    yield put(setResto(response))
    yield put(restoSuccess(response))
  } catch (error) {
    yield put(restoError(error))
  }
}

function* editRestoConfig({ payload: { data } }) {
  try {
    yield call(putRestoContact,
      [
        { key: "resaOnline", val: data.resaOnline || null },
        { key: "resaOffline", val: data.resaOffline || null },
        { key: "cancelTime", val: data.minBeforeCancel || null },
        { key: "cancelDays", val: data.cancelDays || null },
        { key: "cancelHours", val: data.cancelHours || null },
        { key: "cancelMinutes", val: data.cancelMinutes || null },
        { key: "miams", val: data.miams },
      ]
    )
    const response = yield call(getResto)
    yield put(setResto(response))
    yield put(restoSuccess(response))
  } catch (error) {
    yield put(restoError(error))
  }
}

function* editRestoAddress({ payload: { data } }) {
  try {
    yield call(putRestoAddress, {
      id: data.id,
      line1: data.line1,
      line2: data.line2,
      zipcode: data.zipcode,
      city: data.city
    })
    const response = yield call(getResto)
    yield put(setResto(response))
    yield put(restoSuccess(response))
  } catch (error) {
    yield put(restoError(error))
  }
}

function* fetchRoles() {
  try {
    const response = yield call(getRoles)
    yield put(setRoles(response))
    yield put(restoSuccess(response))
  } catch (error) {
    yield put(restoError(error))
  }
}

function* addUser({ payload: data }) {
  try {
    yield call(postRestoUser, data)
    const response = yield call(getResto)
    yield put(setResto(response))
    yield put(restoSuccess(response))
  } catch (error) {
    yield put(restoError(error))
  }
}

function* editUser({ payload: data }) {
  try {
    yield call(putRestoUser, data)
    const response = yield call(getResto)
    yield put(setResto(response))
    yield put(restoSuccess(response))
  } catch (error) {
    yield put(restoError(error))
  }
}

function* deleteUser({ payload: id }) {
  try {
    yield call(deleteRestoUser, id)
    const response = yield call(getResto)
    yield put(setResto(response))
    yield put(restoSuccess(response))
  } catch (error) {
    yield put(restoError(error))
  }
}

function* addShift({ payload: data }) {
  try {
    yield call(postShift, data)
    const response = yield call(getResto)
    yield put(setResto(response))
    yield put(restoSuccess(response))
  } catch (error) {
    yield put(restoError(error))
  }
}

function* editShift({ payload: data }) {
  try {
    yield call(putShift, data)
    const response = yield call(getResto)
    yield put(setResto(response))
    yield put(restoSuccess(response))
  } catch (error) {
    yield put(restoError(error))
  }
}

function* removeShift({ payload: id }) {
  try {
    yield call(deleteShift, id)
    const response = yield call(getResto)
    yield put(setResto(response))
    yield put(restoSuccess(response))
  } catch (error) {
    yield put(restoError(error))
  }
}

function* addKitchen({ payload: data }) {
  try {
    yield call(postKitchen, data)
    const response = yield call(getResto)
    yield put(setResto(response))
    yield put(restoSuccess(response))
  } catch (error) {
    yield put(restoError(error))
  }
}

function* removeKitchen({ payload: id }) {
  try {
    yield call(deleteKitchen, id)
    const response = yield call(getResto)
    yield put(setResto(response))
    yield put(restoSuccess(response))
  } catch (error) {
    yield put(restoError(error))
  }
}

export function* watchResto() {
  yield takeEvery(EDIT_RESTO_INFO, editRestoInfo)
  yield takeEvery(EDIT_RESTO_CONTACT, editRestoContact)
  yield takeEvery(EDIT_RESTO_ADDRESS, editRestoAddress)
  yield takeEvery(FETCH_RESTO, fetchResto)
  yield takeEvery(FETCH_KITCHEN, fetchKitchen)
  yield takeEvery(FETCH_ROLES, fetchRoles)

  // User
  yield takeEvery(ADD_USER, addUser)
  yield takeEvery(EDIT_USER, editUser)
  yield takeEvery(DELETE_USER, deleteUser)

  // Shift
  yield takeEvery(ADD_SHIFT, addShift)
  yield takeEvery(EDIT_SHIFT, editShift)
  yield takeEvery(DELETE_SHIFT, removeShift)

  // Kitchen
  yield takeEvery(ADD_KITCHEN, addKitchen)
  yield takeEvery(DELETE_KITCHEN, removeKitchen)

  // Configuration
  yield takeEvery(EDIT_CONFIG, editRestoConfig)
}

function* RestoSaga() {
  yield all([fork(watchResto)])
}

export default RestoSaga;
