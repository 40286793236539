import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Container, Row, UncontrolledAlert } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { addImage, editImage, fetchImages, removeImage } from "../../store/actions";
import ImageComponent from "./ImageComponent";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

const Images = () => {
    const dispatch = useDispatch();
    const images = useSelector(state => state.image?.data) || [];
    const [galery, setGalery] = useState([])
    const [hasMoved, setHasMoved] = useState(false);

    useEffect(() => {
        dispatch(fetchImages())
    }, [])
    
    useEffect(() => {
        setGalery(images?.filter(el => el.image_type === 'galery'))
    }, [images])

    const newImage = () => {
        console.log('daklj')
    }

    const reorder = () => {
        const payload = galery.map(el => ({ id: el.id?.toString() }))
        dispatch(editImage(payload))
        setHasMoved(false)
    }

    const moveImage = (dragIndex, hoverIndex) => {
        const images = galery
        const draggedImage = images[dragIndex];
        const updatedImages = [...images];
        updatedImages.splice(dragIndex, 1);
        updatedImages.splice(hoverIndex, 0, draggedImage);
        setGalery(updatedImages)
        setHasMoved(true)
    };

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
      }

    function handleAcceptedFiles(files, type) {
        files.forEach(file => {
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            });
            console.log(file)
    
            const formData = new FormData();
            formData.append('file', file);
            dispatch(addImage({ formData, type}));
        });
    }
      
    const deleteImage = id => {
        dispatch(removeImage(id))
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="My restaurant" breadcrumbItem="Images" />
                    {/* <div className="alert alert-primary" role="alert">
                        <strong>Please resize the images so that the white part disappears.</strong>
                    </div> */}
                    <Card>
                        <CardBody>
                            <h4>Mobile and web</h4>
                            <Row>
                                <Col>
                                    <div className="d-flex align-items-center justify-content-between mb-2 mt-4" style={{ maxWidth: '512px' }}>
                                        <h6 className="m-0">Image de liste (Web et Mobile) - 512x512</h6>
                                        <Dropzone
                                            onDrop={acceptedFiles => {
                                                handleAcceptedFiles(acceptedFiles, 'listing')
                                            }}
                                            >
                                            {({ getRootProps, getInputProps }) => (
                                                <div style={{textAlign:"center"}}>
                                                <div
                                                    className="dz-message needsclick"
                                                    {...getRootProps()}
                                                >
                                                    <input {...getInputProps()} />
                                                    <button type="button" className="btn btn-sm btn-primary text-nowrap ms-3">
                                                        <i className="ri-edit-line align-bottom"></i>
                                                        Edit image
                                                    </button>
                                                </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </div>
                                    <img src={images?.find(el => el.image_type === 'listing')?.t_media?.url} className="rounded" width={'512px'} height={'512px'} alt="" />
                                </Col>
                                <Col>
                                    <div className="d-flex align-items-center justify-content-between mb-2 mt-4" style={{ maxWidth: '450px' }}>
                                        <h6 className="m-0">Image de couverture (Web et Mobile) - 820x312</h6>
                                        <Dropzone
                                            onDrop={acceptedFiles => {
                                                handleAcceptedFiles(acceptedFiles, 'cover')
                                            }}
                                            >
                                            {({ getRootProps, getInputProps }) => (
                                                <div style={{textAlign:"center"}}>
                                                <div
                                                    className="dz-message needsclick"
                                                    {...getRootProps()}
                                                >
                                                    <input {...getInputProps()} />
                                                    <button type="button" className="btn btn-sm btn-primary text-nowrap ms-3">
                                                        <i className="ri-edit-line align-bottom"></i>
                                                        Edit image
                                                    </button>
                                                </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </div>
                                    <img src={images?.find(el => el.image_type === 'cover')?.t_media?.url} className="rounded" width={'450px'} height={'312px'} alt="" />
                                    
                                    <div className="d-flex align-items-center mb-2 mt-4">
                                        <h6 className="m-0">Logo (Web) - 128x128</h6>
                                        <Dropzone
                                            onDrop={acceptedFiles => {
                                                handleAcceptedFiles(acceptedFiles, 'logo')
                                            }}
                                            >
                                            {({ getRootProps, getInputProps }) => (
                                                <div style={{textAlign:"center"}}>
                                                <div
                                                    className="dz-message needsclick"
                                                    {...getRootProps()}
                                                >
                                                    <input {...getInputProps()} />
                                                    <button type="button" className="btn btn-sm btn-primary text-nowrap ms-3">
                                                        <i className="ri-edit-line align-bottom"></i>
                                                        Edit image
                                                    </button>
                                                </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </div>
                                    <img src={images?.find(el => el.image_type === 'logo')?.t_media?.url} className="rounded" width={'128px'} height={'128px'} alt="" />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card className="mt-4">
                        <CardBody>
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h4 className="m-0">Gallery</h4>
                                <div className="d-flex justify-content-end align-items-center flex-wrap">
                                    <button disabled={!hasMoved} onClick={() => reorder()} type="button" className="btn btn-primary text-nowrap">
                                        <i className="ri-check-line align-bottom me-1"></i>
                                        Confirm order
                                    </button>
                                    {/* <button onClick={() => handleAcceptedFiles()} type="button" className="ms-2 btn btn-primary text-nowrap">
                                        <i className="ri-add-line align-bottom me-1"></i>
                                        New image
                                    </button> */}
                                    <Dropzone
                                        onDrop={acceptedFiles => {
                                            handleAcceptedFiles(acceptedFiles, 'galery')
                                        }}
                                        >
                                        {({ getRootProps, getInputProps }) => (
                                            <div style={{textAlign:"center"}}>
                                            <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()} />
                                                <button type="button" className="ms-2 btn btn-primary text-nowrap">
                                                    <i className="ri-add-line align-bottom me-1"></i>
                                                    New image
                                                </button>
                                            </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>
                            </div>
                            <DndProvider backend={HTML5Backend}>
                                <div className="d-flex align-items-center flex-wrap">
                                    {galery.map((el, index) => (
                                        <div className="position-relative" key={el.id}>
                                            <button onClick={() => deleteImage(el.id)} className="position-absolute top-0 end-0 btn btn-danger btn-sm rounded-circle">
                                                <i className="ri-close-line align-bottom"></i>
                                            </button>
                                            <ImageComponent
                                                index={index}
                                                image={el.t_media?.url}
                                                moveImage={moveImage}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </DndProvider>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Images;