export const FETCH_PROMOTIONS = "FETCH_PROMOTIONS";
export const SET_PROMOTIONS = "SET_PROMOTIONS";
export const FETCH_PROMOTION = "FETCH_PROMOTION";
export const SET_PROMOTION = "SET_PROMOTION";
export const ADD_PROMOTION = "ADD_PROMOTION";
export const EDIT_PROMOTION = "EDIT_PROMOTION";
export const DELETE_PROMOTION = "DELETE_PROMOTION";
export const PROMOTION_SUCCESS = "PROMOTION_SUCCESS";
export const PROMOTION_ERROR = "PROMOTION_ERROR";


