import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { Card, CardBody, CardTitle } from "reactstrap";

const LoadingContainer = (props) => <div>Loading...</div>

const Location = (props) => {

  const mapStyles = {
    width: '100%',
    height: 430,
  };
  return (
  <Card style={{ height: 500 }}>
    <CardBody>

        <CardTitle className="mt-0">Location</CardTitle>
        <div id="panorama" className="gmaps-panaroma" style={{ position: "relative" }}>
            <Map
                google={props.google}
                zoom={8}
                style={mapStyles}
                initialCenter={{ lat: 8.7832, lng: 34.5085 }}
            >
              <Marker position={{ lat: 48.00, lng: -122.00 }} />
            </Map>
        </div>
    </CardBody>
  </Card>
  )  
}
export default (
  GoogleApiWrapper({
      apiKey: "AIzaSyAh3iRS67SNxJEhBk76CKNJmx-D45eGzTc",
      LoadingContainer: LoadingContainer,
      v: "3",
  })(Location)
)
