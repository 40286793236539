import { FETCH_RESERVATIONS, SET_RESERVATIONS, RESERVATION_SUCCESS, RESERVATION_ERROR } from "./actionTypes";

const initialState = {
  reservations: [],
  loading: false,
  error: "",
  success: "",
};

const reservation = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RESERVATIONS:
      state = { ...state, loading: true };
      break;
    case SET_RESERVATIONS:
      state = { ...state, loading: false, reservations: action.payload };
      break;
    // case ADD_RESERVATION:
    //     state = { ...state, loading: false, roles: action.payload };
    //     break;
    // case EDIT_RESERVATION:
    //   state = { ...state, loading: true };
    //   break;
    // case DELETE_RESERVATION:
    //   state = { ...state, loading: false, roles: action.payload };
    //   break;
    case RESERVATION_SUCCESS:
      state = { ...state, success: action.payload, loading: false, };
      break;
    case RESERVATION_ERROR:
      state = { ...state, error: action.payload, loading: false, };
      break;
    default:
      state = { ...state, loading: false };
      break;
  }
  return state;
};

export default reservation;
