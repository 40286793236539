export const FETCH_TABLES = "FETCH_TABLES";
export const SET_TABLES = "SET_TABLES";
export const DELETE_ROOM = "DELETE_ROOM";
export const FETCH_ROOMS = "FETCH_ROOMS";
export const SET_ROOMS = "SET_ROOMS";
export const FETCH_ROOM = "FETCH_ROOM";
export const SET_ROOM = "SET_ROOM";
export const ADD_ROOM = "ADD_ROOM";
export const EDIT_ROOM = "EDIT_ROOM";
export const TABLE_SUCCESS = "TABLE_SUCCESS";
export const TABLE_ERROR = "TABLE_ERROR";


