import { ADD_IMAGE, DELETE_IMAGE, EDIT_IMAGE, FETCH_IMAGES, IMAGE_ERROR, IMAGE_SUCCESS, SET_IMAGE, SET_IMAGES, SET_ROOM, SET_ROOMS, SET_TABLES, TABLE_ERROR, TABLE_SUCCESS } from "./actionTypes";

const initialState = {
  data: [],
  // image: null,
  loading: false,
  error: "",
  success: "",
};

const image = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_IMAGES:
      state = { ...state, loading: true };
      break;
    case SET_IMAGES:
      state = { ...state, loading: false, data: action.payload };
      break;
    // case FETCH_IMAGE:
    //     state = { ...state, loading: true };
    //     break;
    // case SET_IMAGE:
    //     state = { ...state, loading: false, image: action.payload };
    //     break;
    case ADD_IMAGE:
        state = { ...state, loading: true };
        break;
    case EDIT_IMAGE:
      state = { ...state, loading: true };
      break;
    case DELETE_IMAGE:
      state = { ...state, loading: true };
      break;
    case IMAGE_SUCCESS:
      state = { ...state, success: action.payload, loading: false, };
      break;
    case IMAGE_ERROR:
      state = { ...state, error: action.payload, loading: false, };
      break;
    default:
      state = { ...state, loading: false };
      break;
  }
  return state;
};

export default image;
