import React, { useState, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { AccordionBody, AccordionHeader, AccordionItem, Button, Card, CardBody, CardTitle, Col, Container, Input, Modal, Row, UncontrolledAccordion } from 'reactstrap';
import "flatpickr/dist/themes/material_green.css";

import Breadcrumbs from '../../components/Common/Breadcrumb';
import "flatpickr/dist/themes/material_green.css";
import _ from 'lodash';
import Details from './details';
import { fetchMenu, fetchMenus, removeMenu } from '../../store/menu/actions';
import MenuItem from './MenuItem';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const Menu = () => {
  const [current, setCurrent] = useState(null);
  const [q, setQ] = useState('');
  const dispatch = useDispatch();
  const menus = useSelector(state => state.menu?.data || [])
  const loading = useSelector(state => state.table?.loading || false)
  const [fMenus, setFMenus] = useState([]);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);


  const toggleModal = data => {
    if (data) setCurrent(data)
    setModal(true)
  }

  const toggleModal1 = data => {
    setCurrent(data)
    setModal1(true)
  }

  useEffect(() => {
    dispatch(fetchMenus())
  }, [])

  useEffect(() => {
    if (q && menus) {
      const filteredMenus = fMenus.filter(el => {
        const fullName = `${el.name} ${el.t_room?.avr_price} ${el.cover}`.toString().toLowerCase();
        return fullName.includes(q.toLowerCase())
      })
      setFMenus(filteredMenus) 
    }
    else setFMenus(menus)
  }, [menus, q])

  const deleteMenu = () => {
    dispatch(removeMenu(current.id))
  }

  const moveMenu = (dragIndex, hoverIndex) => {
    const elements = fMenus
    const draggedEl = elements[dragIndex];
    const updatedEl = [...elements];
    updatedEl.splice(dragIndex, 1);
    updatedEl.splice(hoverIndex, 0, draggedEl);
    setFMenus(updatedEl)
  };

    return (
        <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="My restaurant" breadcrumbItem="Menus" />
            { /* Tables */ }
            <Card>
              <CardBody>
              <Row>
                <Col md={6}>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-center justify-content-end">
                      <form className="app-search d-none d-lg-block p-0 me-2" style={{ width: '100%' }}>
                          <div className="position-relative">
                          <Input
                            value={q}
                            onChange={e => setQ(e.target.value)}
                            type="text"
                            debounce={500}
                            placeholder="Search by menu name"
                            name="q" />
                          <span className="ri-search-line"></span>
                          </div>
                      </form>
                      <button onClick={() => toggleModal()} type="button" className="btn btn-primary text-nowrap">
                        <i className="ri-add-line align-bottom me-1"></i>
                        Add Menu
                      </button>
                  </div>
                </Col>
            </Row>
            <DndProvider backend={HTML5Backend}>
                <UncontrolledAccordion flush stayOpen>
                  {fMenus.map((el, index) => (
                    <MenuItem key={index} el={el} index={index} moveMenu={moveMenu} />
                  ))}
                </UncontrolledAccordion>
            </DndProvider>
              </CardBody>
            </Card>
  
          </Container>
        </div>
        <Modal
                isOpen={modal}
                toggle={() => { toggleModal() }}
                centered
                scrollable={true}
                size="lg"
                >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">
                    {current ? 'Update' : 'Add'} room
                    </h5>
                    <button
                    type="button"
                    onClick={() => { setModal(false) }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ maxHeight: 800 }}>
                    <Details info={current} />
                </div>
            </Modal>

            <Modal
                isOpen={modal1}
                toggle={() => { toggleModal1() }}
                centered
                >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">
                    Delete category
                    </h5>
                    <button
                    type="button"
                    onClick={() => { setModal1(false) }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body d-flex justify-content-center" style={{ maxHeight: 400 }}>
                    <h5>Do you want to delete <strong>{current?.name}</strong>?</h5>
                </div>
                <div className="modal-footer d-flex justify-content-center">
                    <button onClick={() => deleteMenu()} type="submit" class="btn btn-danger px-4">Yes</button>
                    <button onClick={() => setModal(false)} type="submit" class="btn btn-secondary px-4">No</button>
                </div>
            </Modal>
      </React.Fragment>
    );
};

export default Menu;